import React, { useEffect, useState, useRef, useContext } from "react";
import "../../../CSS/Admin/penjadwalan.css";
import "../../../CSS/Admin/pelanggan.css";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getLaporanById,
  updateData,
} from "../../../Model/Laporan/laporanModel";
import { getDataPelanggan } from "../../../Model/Pelanggan/pelangganModel";
import Select from "react-select";
import { AuthContext } from "../../../Routes/AuthContext";
const AdminEditLaporan = ({ show, onHide, setTrigger, idLaporan }) => {
  const { token } = useContext(AuthContext);

  // const { id } = useParams();

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false); // State untuk notifikasi gagal
  const [dataLaporan, setDataLaporan] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(false);

  // State untuk menampung data pelanggan
  const [dataPelanggan, setDataPelanggan] = useState([]);
  const [errMessage, setErrMessage] = useState("");

  const dateInputRef = useRef(null);

  //variable react select
  const optionPelanggan = dataPelanggan
    ? dataPelanggan.map((item) => ({
        value: item.idpelanggan,
        label: item.nama_pelanggan + `  (${item.idpelanggan})`,
      }))
    : [];

  // Fungsi untuk menutup notifikasi
  const closeAlert = () => {
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };

  const validasiSchema = Yup.object({
    tanggal_laporan: Yup.string().required(
      "Tanggal expired tidak boleh kosong"
    ),
  });

  const formik = useFormik({
    initialValues: {
      id_laporan: dataLaporan ? dataLaporan.id_laporan : "",
      idpelanggan: dataLaporan ? dataLaporan.idpelanggan : "",
      nama_pelanggan: dataLaporan ? dataLaporan.nama_pelanggan : "",
      alamat: dataLaporan ? dataLaporan.alamat : "",
      file_laporan: dataLaporan ? dataLaporan.file_laporan : "",
      tanggal_laporan: dataLaporan ? dataLaporan.tanggal_laporan : "",
      catatan: dataLaporan ? dataLaporan.catatan : "",
      nama_file_laporan: dataLaporan ? dataLaporan.nama_file_laporan : "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData();
      formData.append("idpelanggan", values.idpelanggan);
      formData.append("file_laporan", values.file_laporan);
      formData.append("tanggal_laporan", values.tanggal_laporan);
      formData.append("catatan", values.catatan);

      updateData(idLaporan, formData, token)
        .then((response) => {
          if (response.data.status === "gagal") {
            setErrMessage(response.data.errMessage);
            setShowErrorAlert(true);
            setTimeout(closeAlert, 2000);
          } else {
            setTrigger((prev) => !prev);
            setTriggerFetch((prev) => !prev);
            setShowSuccessAlert(true);
            setTimeout(closeAlert, 2000);
          }
        })
        .catch((error) => {
          if (error.response.data.errMessage) {
            setErrMessage(error.response.data.errMessage);
          } else {
            setErrMessage("Gagal mengupdate data laporan");
          }
          setShowErrorAlert(true);
          setTimeout(closeAlert, 2000);
        });
    },
    validationSchema: validasiSchema,
    enableReinitialize: true,
  });

  // Mengambil data pelanggan berdasarkan id
  const selectedIdPelanggan = optionPelanggan.find((option) => {
    return option.value === formik.values.idpelanggan;
  });

  useEffect(() => {
    if (idLaporan) {
      Promise.all([getDataPelanggan(token), getLaporanById(idLaporan, token)])
        .then(([pelangganResponse, laporanResponse]) => {
          setDataPelanggan(pelangganResponse.data.data);
          setDataLaporan(laporanResponse.data.data);
        })
        .catch((error) => {
          setErrMessage(error.response.data.errMessage);
        });
    }
  }, [idLaporan, triggerFetch]);
  return (
    <div>
      {showSuccessAlert && (
        <div
          style={{ zIndex: "9999" }}
          className="alert alert-success border-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          Data Laporan berhasil Diupdate
        </div>
      )}
      {showErrorAlert && (
        <div
          style={{ zIndex: "9999" }}
          className="alert alert-danger border-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          {errMessage}
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Modal show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Laporan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-3">
              <label>Id Laporan:</label>
              <input
                name="id_laporan"
                className="form-select"
                aria-label="Default select example"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.id_laporan}
                disabled
              ></input>
              {formik.touched.id_laporan && formik.errors.id_laporan ? (
                <div className="text-danger">{formik.errors.id_laporan}</div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label>Nama Pelanggan</label>
              <input
                name="nama_pelanggan"
                type="text"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nama_pelanggan}
                disabled
                // onChange={(e) => setjamMulai(e.target.value)}
              />
              {formik.touched.nama_pelanggan && formik.errors.nama_pelanggan ? (
                <div className="text-danger">
                  {formik.errors.nama_pelanggan}
                </div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label>Id Pelanggan:</label>
              <Select
                options={optionPelanggan}
                name="idpelanggan"
                onChange={(selectedOption) => {
                  formik.setFieldValue("idpelanggan", selectedOption.value);
                }}
                onBlur={formik.handleBlur}
                defaultValue={selectedIdPelanggan}
                value={selectedIdPelanggan}
                // onChange={(e) => setNamaTeknisi(e.target.value)}
              />
              {formik.touched.idpelanggan && formik.errors.idpelanggan ? (
                <div className="text-danger">{formik.errors.idpelanggan}</div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label onClick={() => dateInputRef.current.click()}>
                Tanggal Laporan
              </label>
              <input
                ref={dateInputRef}
                name="tanggal_laporan"
                type="date"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tanggal_laporan}

                // onChange={(e) => setjamMulai(e.target.value)}
              />
              {formik.touched.tanggal_laporan &&
              formik.errors.tanggal_laporan ? (
                <div className="text-danger">
                  {formik.errors.tanggal_laporan}
                </div>
              ) : null}
            </div>
            <label>Masukkan Catatan : </label>
            <div class="form-floating mb-3">
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                id="floatingTextarea2"
                name="catatan"
                value={formik.values.catatan}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ height: "100px" }}
              ></textarea>
            </div>
            <div className="form-group mb-3">
              <label>Masukkan File Laporan : </label>
              <span className="text-muted d-block mb-1">
                *pdf, jpg, jpeg, png, Max file 5 mb
              </span>
              <input
                name="file_laporan"
                type="file"
                className="form-control"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(
                    "file_laporan",
                    event.currentTarget.files[0]
                  );
                }}
                // value={jamMulai}
                // onChange={(e) => setjamMulai(e.target.value)}
              />
              {formik.values.file_laporan && (
                <div className="mt-2">
                  <strong>File yang telah diunggah:</strong>{" "}
                  <a
                    href={formik.values.file_laporan}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formik.values.nama_file_laporan ||
                      "Tidak ada file yang diunggah"}
                  </a>
                </div>
              )}
              {formik.touched.file_laporan && formik.errors.file_laporan ? (
                <div className="text-danger">{formik.errors.file_laporan}</div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={formik.handleSubmit}>
              Edit Data
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default AdminEditLaporan;
