import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Headerr from "../Components/Headerr";
import "../CSS/Login/login.css";
import { loginUser } from "../Model/User/userModel";
import { AuthContext } from "../Routes/AuthContext";
import Cookies from "js-cookie";

// dotenv.config();
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setIsAuthenticated, setRole, setToken, setExpired } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await loginUser(username, password);
      handleSuccessfullLogin(data);
    } catch (error) {
      handleFailedLogin(error);
    }
  };

  const handleSuccessfullLogin = (data) => {
    setIsAuthenticated(true);
    setExpired(data.expired);
    setRole(data.role);
    setToken(data.token);

    // Navigation

    if (data.role === "admin") {
      navigate("/admin-home");
    } else if (data.role === "pelanggan") {
      Cookies.set("idCustomer", "TKSEW");
      navigate("/pelanggan");
    } else {
      navigate("/");
    }
    // localStorage.setItem = ("userToken", token);
  };

  const handleFailedLogin = (error) => {
    console.log("error");
    console.log(error);
    setError(error);
  };
  return (
    <div>
      <Headerr></Headerr>
      <section class="h-100 ">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-xl-10">
              <div class="card rounded-3 text-black">
                <div class="row g-0">
                  <div class="col-lg-6">
                    <div class="card-body p-md-5 mx-md-4">
                      <div class="text-center">
                        {/* <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                          alt="logo"
                          className="width"
                        ></img> */}
                        <h4 class="mt-1 mb-5 pb-1">We are The TATA SG TEAM</h4>
                      </div>

                      <form onSubmit={handleLogin}>
                        {error ? (
                          <div class="alert alert-danger" role="alert">
                            {error}
                          </div>
                        ) : (
                          ""
                        )}

                        <p>Please login to your account</p>

                        <div class="form-outline mb-4">
                          <input
                            type="text"
                            id="form2Example11"
                            class="form-control"
                            value={username}
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          <label
                            class="form-label"
                            for="form2Example11"
                          ></label>
                        </div>

                        <div class="form-outline mb-4">
                          <input
                            type="password"
                            id="form2Example22"
                            class="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <label
                            class="form-label"
                            for="form2Example22"
                          ></label>
                        </div>

                        <div class="text-center pt-1 mb-2 pb-1">
                          <button
                            class="btn btn-primary text-dark btn-block fa-lg gradient-custom-2 w-100"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4 class="mb-4 text-dark">
                        We are more than just a company
                      </h4>
                      <p class="small mb-0 text-dark">
                        Selamat datang di Tata Sinar Gemilang, spesialis
                        pengendalian hama terpercaya dan berpengalaman. Kami
                        berdedikasi untuk melindungi rumah dan bisnis Anda dari
                        serangan hama yang merusak dan mengganggu.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
