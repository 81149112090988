import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Sectionn from "../../../Components/Sectionn";
import "../../../CSS/Admin/pelanggan.css";
import Headerr from "../../../Components/Headerr";
import Select from "react-select";
import {
  getDataTeknisi,
  getLaporanTreatment,
  getTeknisiById,
} from "../../../Model/Teknisi/teknisiModel";
import { AuthContext } from "../../../Routes/AuthContext";
import { listBulan, statusPenjadwalan } from "../../../Utils/items";

const AdminLaporanTreatment = () => {
  // Data Teknisi
  // const { idTeknisi } = useParams();
  const navigate = useNavigate();
  // Asumsi state idTeknisi dan fungsi pembaruan state-nya sudah ada
  const [idTeknisi, setIdTeknisi] = useState("TTSG-tkns-001");
  const [dataTeknisi, setDataTeknisi] = useState([]);
  // ................................................

  const [laporanStatus, setLaporanStatus] = useState("belum selesai");
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const [laporanTreatment, setLaporanTreatment] = useState([]);

  // variable nama teknisi
  const [namaTeknisi, setNamaTeknisi] = useState("");
  const [jumlahTreatment, setJumlahTreatment] = useState(0);

  const [statusBulan, setStatusBulan] = useState(currentMonth);

  const { token } = useContext(AuthContext);
  const [triggerFetch, setTriggerFetch] = useState(false); // [1

  // Handle alert
  const [errorMessages, setErrorMessages] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  // state untuk pagination

  const [queryTahun, setQueryTahun] = useState(currentYear);
  const [keywordTahun, setKeywordTahun] = useState("");
  // -------------------  ---------------------------

  // Fungsi untuk mendapatkan nama bulan
  const getNamaBulan = (bulan) => {
    // Konversi bulan ke string untuk pencocokan
    const bulanString = bulan.toString();

    // Cari objek dalam listBulan yang memiliki value sama dengan bulanString
    const bulanObj = listBulan.find((item) => item.value === bulanString);

    // Jika objek ditemukan, kembalikan label-nya, jika tidak kembalikan string kosong atau nilai default
    return bulanObj ? bulanObj.label : "Bulan tidak ditemukan";
  };

  // Fungsi untuk menangani perubahan pada Select
  const handleSelectChange = (selectedOption) => {
    setIdTeknisi(selectedOption.value); // Memperbarui state idTeknisi dengan nilai baru

    // navigate(`/admin-home/teknisi/${selectedOption.value}`);
  };

  const statusBulanString = getNamaBulan(statusBulan);
  //variable react select
  const optionTeknisi = dataTeknisi
    ? dataTeknisi.map((item) => ({
        value: item.idteknisi,
        label: item.nama_teknisi + `  (${item.idteknisi})`,
      }))
    : [];
  // Fungsi untuk mencari data
  const searchData = (e) => {
    e.preventDefault();

    if (queryTahun === "") {
      setKeywordTahun(currentYear);
      setTriggerFetch((prev) => !prev);
    } else {
      setKeywordTahun(queryTahun);
      setTriggerFetch((prev) => !prev);
    }
  };

  useEffect(() => {
    Promise.all([
      getTeknisiById(idTeknisi, token),
      getLaporanTreatment(
        idTeknisi,
        {
          year: keywordTahun || currentYear,
          month: statusBulan,
          status: laporanStatus,
        },
        token
      ),
      getDataTeknisi(token),
    ])
      .then(
        ([teknisiResponse, laporanTreatmentResponse, teknisiDataResponse]) => {
          setJumlahTreatment(laporanTreatmentResponse.data.data.length);
          setLaporanTreatment(laporanTreatmentResponse.data.data);
          console.log(laporanTreatmentResponse.data.data);
          setNamaTeknisi(teknisiResponse.data.data.nama_teknisi);
          setDataTeknisi(teknisiDataResponse.data.data);
          setIdTeknisi(teknisiDataResponse.data.data.idteknisi[0]);
          console.log("");
          console.log("TeknisiDataResponse.data");
          console.log(teknisiDataResponse.data.data);
        }
      )
      .catch((error) => {
        console.log(error.response);
        // Handle error here
      });
  }, [triggerFetch, statusBulan, idTeknisi, token, laporanStatus]);
  //
  return (
    <div>
      <Headerr />
      <Sectionn>
        {showSuccessAlert && (
          <div
            style={{ zIndex: 999 }}
            className="alert alert-success border-danger position-fixed top-50 start-50 translate-middle "
            role="alert"
          >
            Sukses
          </div>
        )}
        {showErrorAlert && (
          <div
            style={{ zIndex: 999 }}
            className="alert alert-danger border-danger position-fixed top-50 start-50 translate-middle "
            role="alert"
          >
            {errorMessages}
          </div>
        )}
        <h1 className="text-center mb-5 title-penjadwalan">
          Laporan Treatment {statusBulanString} Tahun {queryTahun}
        </h1>
        <form onSubmit={searchData}>
          <div className="d-flex mb-3">
            <select
              onChange={(e) => setStatusBulan(e.target.value)}
              value={statusBulan}
              class="form-select form-select-sm w-50"
              aria-label=".form-select-sm example"
            >
              <option>Pilih Bulan saat ini</option>
              {listBulan.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <input
              className="form-control w-25 ms-4"
              placeholder="Masukkan Tahun"
              value={queryTahun}
              onChange={(e) => setQueryTahun(e.target.value)}
            ></input>
          </div>
          <div className="row mt-2 ms-0 gap-3">
            <select
              onChange={(e) => setLaporanStatus(e.target.value)}
              value={laporanStatus}
              className="form-select form-select-sm w-50"
              aria-label=".form-select-sm example"
            >
              <option>Pilih status Penjadwalan</option>
              {statusPenjadwalan.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <Select
              styles={{ zIndex: 9999 }}
              required
              className="form-control-sm ms-0 p-0 mb-3 w-50"
              name="idteknisi"
              placeholder="Masukkan nama Teknisi"
              options={optionTeknisi}
              value={optionTeknisi.find((obj) => obj.value === idTeknisi)}
              menuPlacement="top"
              onChange={handleSelectChange}
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-sm mb-sm-2 mb-md-0 mt-2"
          >
            Search
          </button>
        </form>
        <div className="mt-5 d-sm-flex">
          <h6 className="fs-6 me-5">Nama Teknisi : {namaTeknisi}</h6>
          <h6>Jumlah Treatment : {jumlahTreatment}</h6>
        </div>

        {laporanTreatment.length === 0 ? (
          <div className="container text-center text-white p-2 bg-secondary">
            Data Treatment Laporan Kosong
          </div>
        ) : (
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  No
                </th>
                <th scope="col" className="text-center">
                  Id Penjadwalan
                </th>
                <th scope="col" className="text-center">
                  Nama pelanggan
                </th>
                <th scope="col" className="text-center">
                  Lokasi
                </th>
                <th scope="col" className="text-center">
                  Periode
                </th>
                <th scope="col" className="text-center">
                  Jenis Treatment
                </th>
                <th scope="col" className="text-center">
                  Treatment ke{" "}
                </th>
                <th scope="col" className="text-center">
                  Tanggal Treatment
                </th>
                <th scope="col" className="text-center">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {laporanTreatment.map((item, index) => (
                <tr key={index}>
                  <td className="px-2">{index + 1}</td>
                  <td className="px-2">{item.idpenjadwalan}</td>
                  <td className="px-2">{item.nama_pelanggan}</td>
                  <td className="px-2">{item.lokasi}</td>
                  <td className="px-2">{item.periode_kontrak}</td>
                  <td className="px-2">{item.jenis_pekerjaan}</td>
                  <td className="px-2">{item.jumlah_kunjungan}</td>
                  <td className="px-2">{item.tanggal}</td>
                  <td className="px-2">{item.status_penjadwalan}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Sectionn>
    </div>
  );
};

export default AdminLaporanTreatment;
