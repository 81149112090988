import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../Assets/Image/Logo.png";
import "../../CSS/Admin/navbar_admin.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg ps-5 pe-5 py-2">
      <div className="container-fluid">
        <Link to="/" className="tataSgLink">
          <img
            src={logo}
            className="navbar-brand img-fluid ms-4 custom-image"
            alt="Logo"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse custom-navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  color: isActive ? "blue" : "red !important",
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/aboutUs"
              >
                Tentang Kami
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/contact-us"
              >
                Hubungi Kami
              </NavLink>
            </li>
            <li>
              <div class="dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDarkDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Layanan kami
                </a>

                <ul
                  class="dropdown-menu  custom-dropdown-menu"
                  aria-labelledby="navbarDarkDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="/pest-control">
                      Pest Control
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/rodent-control">
                      Rodent Control
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/termite-control">
                      Termite Control
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/disinfeksi">
                      Disinfeksi
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/fumigasi">
                      Fumigasi
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/katalog-harga"
              >
                Katalog Harga
              </NavLink>
            </li> */}
          </ul>
        </div>
        <div
          className="collapse navbar-collapse custom-navbar-collapse  justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/login"
              >
                Login
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
