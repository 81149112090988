import React, { useContext, useEffect, useState } from "react";
import "../../../CSS/Admin/penjadwalan.css";
import "../../../CSS/Admin/pelanggan.css";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { itemIdPelanggan, statusTagihan } from "../../../Utils/items";
import { getDataPenjadwalan } from "../../../Model/PenjadwalanModel/penjadwalanModel";
import {
  getSertifikatById,
  updateData,
} from "../../../Model/SertifikatRayap/sertifikatRayapModel";
import { AuthContext } from "../../../Routes/AuthContext";
// import { getDataSertifikat } from "../../../Model/SertifikatRayap/sertifikatRayapModel";

const AdminEditSertifikatRayap = ({
  show,
  onHide,
  setTrigger,
  idSertifikat,
}) => {
  const { token } = useContext(AuthContext);

  // const { id } = useParams();
  const [temptFileTagihan, setTemptFileTagihan] = useState("");
  const [temptFileBuktiTf, setTemptFileBuktiTf] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false); // State untuk notifikasi gagal
  const [dataSertifikat, setDataSertifikat] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(false);

  // State untuk menampung data pelanggan
  const [dataPenjadwalan, setDataPenjadwalan] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [litsIdPelanggan, setListIdPelanggan] = useState([]);
  // Membuat array dari nilai 'value' dalam idPelanggan
  const statussItemsList = statusTagihan.map((item) => item.value);

  // Fungsi untuk menutup notifikasi
  const closeAlert = () => {
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };

  const validasiSchema = Yup.object({
    tanggal_expired: Yup.string().required(
      "Tanggal expired tidak boleh kosong"
    ),
  });

  const formik = useFormik({
    initialValues: {
      idsertifikat_rayap: dataSertifikat
        ? dataSertifikat.idsertifikat_rayap
        : "",
      idpenjadwalan: dataSertifikat ? dataSertifikat.idpenjadwalan : "",
      nama_pelanggan: dataSertifikat ? dataSertifikat.nama_pelanggan : "",
      tanggal: dataSertifikat ? dataSertifikat.tanggal : "",
      alamat: dataSertifikat ? dataSertifikat.alamat : "",
      file_sertifikat: dataSertifikat ? dataSertifikat.file_sertifikat : "",
      nama_file_sertifikat: dataSertifikat
        ? dataSertifikat.nama_file_sertifikat
        : "",
      tanggal_expired: dataSertifikat ? dataSertifikat.tanggal_expired : "",
    },
    onSubmit: (values, { setSubmitting }) => {
    
      const formData = new FormData();
      formData.append("idpenjadwalan", values.idpenjadwalan);
      formData.append("file_sertifikat", values.file_sertifikat);
      formData.append("file_bukti_tf", values.file_bukti_tf);
      formData.append("tanggal_expired", values.tanggal_expired);

      updateData(idSertifikat, formData, token)
        .then((response) => {
          if (response.data.status === "gagal") {
            setErrMessage(response.data.errMessage);
            setShowErrorAlert(true);
            setTimeout(closeAlert, 2000);
          } else {
           
            setTrigger((prev) => !prev);
            setTriggerFetch((prev) => !prev);
            setShowSuccessAlert(true);
            setTimeout(closeAlert, 2000);
          }
        })
        .catch((error) => {
        
          if (error.response.data.errMessage) {
            setErrMessage(error.response.data.errMessage);
          } else {
            setErrMessage("Gagal mengupdate data tagihan");
          }
          setShowErrorAlert(true);
          setTimeout(closeAlert, 2000);
        });
    },
    validationSchema: validasiSchema,
    enableReinitialize: true,
  });
  useEffect(() => {
    if (idSertifikat) {
      Promise.all([
        getDataPenjadwalan(token),
        getSertifikatById(idSertifikat, token),
      ])
        .then(([penjadwalanResponse, sertifikatResponse]) => {
          setDataPenjadwalan(penjadwalanResponse.data.data);
          setDataSertifikat(sertifikatResponse.data.data);
       
        })
        .catch((error) => {
         
          setErrMessage(error.response.data.errMessage);
        });
    }
  }, [idSertifikat, triggerFetch]);
  return (
    <div>
      {showSuccessAlert && (
        <div
          style={{ zIndex: "9999" }}
          className="alert alert-success border-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          Data Sertifikat berhasil Diupdate
        </div>
      )}
      {showErrorAlert && (
        <div
          style={{ zIndex: "9999" }}
          className="alert alert-danger border-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          {errMessage}
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Modal show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Sertifikat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-3">
              <label>Id Sertifikat:</label>
              <input
                name="idsertifikat_rayap"
                className="form-select"
                aria-label="Default select example"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idsertifikat_rayap}
                disabled
              ></input>
              {formik.touched.idsertifikat_rayap &&
              formik.errors.idsertifikat_rayap ? (
                <div className="text-danger">
                  {formik.errors.idsertifikat_rayap}
                </div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label>Id Penjadwalan:</label>
              <input
                name="idpenjadwalan"
                className="form-select"
                aria-label="Default select example"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.idpenjadwalan}
                disabled
              ></input>
              {formik.touched.idpenjadwalan && formik.errors.idpenjadwalan ? (
                <div className="text-danger">{formik.errors.idpenjadwalan}</div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label>Nama Pelanggan</label>
              <input
                name="nama_pelanggan"
                type="text"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nama_pelanggan}
                disabled
                // onChange={(e) => setjamMulai(e.target.value)}
              />
              {formik.touched.nama_pelanggan && formik.errors.nama_pelanggan ? (
                <div className="text-danger">
                  {formik.errors.nama_pelanggan}
                </div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label>Tanggal Pekerjaan</label>
              <input
                name="tanggal"
                type="text"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  formik.values.tanggal
                    ? formik.values.tanggal.split("T")[0]
                    : ""
                }
                disabled
                // onChange={(e) => setjamMulai(e.target.value)}
              />
              {formik.touched.tanggal && formik.errors.tanggal ? (
                <div className="text-danger">{formik.errors.tanggal}</div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label>Masukkan File Sertifikat : </label>
              <span className="text-muted d-block mb-1">
                *pdf, jpg, jpeg, png, Max file 5 mb
              </span>
              <input
                name="file_sertifikat"
                type="file"
                className="form-control"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(
                    "file_sertifikat",
                    event.currentTarget.files[0]
                  );
                }}
                // value={jamMulai}
                // onChange={(e) => setjamMulai(e.target.value)}
              />
              {formik.values.file_sertifikat && (
                <div className="mt-2">
                  <strong>File yang telah diunggah:</strong>{" "}
                  <a
                    href={formik.values.file_sertifikat}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formik.values.nama_file_sertifikat ||
                      "Tidak ada file yang diunggah"}
                  </a>
                </div>
              )}
              {formik.touched.file_sertifikat &&
              formik.errors.file_sertifikat ? (
                <div className="text-danger">
                  {formik.errors.file_sertifikat}
                </div>
              ) : null}
            </div>

            <div className="form-group mb-3">
              <label>Tanggal Expired : </label>
              <input
                name="tanggal_expired"
                type="date"
                className="form-control"
                value={formik.values.tanggal_expired}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.tanggal_expired &&
              formik.errors.tanggal_expired ? (
                <div className="text-danger">
                  {formik.errors.tanggal_expired}
                </div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={formik.handleSubmit}>
              Edit Data
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default AdminEditSertifikatRayap;
