import React, { useState, useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Cookies from "js-cookie";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { loginUser } from "../Model/User/userModel";
import { useAxiosJwtPlgn } from "../Model/Config/axiosConfigP";

export const ProtectedRouteTeknisi = ({ children, requiredRole }) => {
  const [tokenValidation, setTokenValidation] = useState(false);
  const [isEffectComplete, setIsEffectComplete] = useState(false);
  const axiosJwtPlgn = useAxiosJwtPlgn();

  // Param Url
  const queryparam = new URLSearchParams(useLocation().search);

  const {
    isAuthenticated,
    role,
    setRole,
    token,
    setToken,
    setIsAuthenticated,
    expired,
    setExpired,
  } = useContext(AuthContext);

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    let token = queryparam.get("token");

    if (!token) {
      token = Cookies.get("auth");
      // Cek token dari cookie
      if (token === undefined || token === null || token === "") {
        setToken(null);
        setRole(null);
        setExpired(null);
        setIsAuthenticated(false);
      }
      // Jika token dari cookie ada
      else {
        const decoded = jwtDecode(token);
        // set bearer token
        Cookies.set("auth", token);
        const baseUrl = `${process.env.REACT_APP_BACKEND_URL}verify-teknisi/${token}`;
        try {
          await axiosJwtPlgn
            .get(baseUrl, {
              withCredentials: true,
            })
            .then((response) => {
              setToken(token);
              setRole(decoded.jabatan);
              setExpired(decoded.exp);
              setIsAuthenticated(true);
              axiosJwtPlgn.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${token}`;
            })
            .catch((error) => {
              setToken(null);
              setRole(null);
              setExpired(null);
              setIsAuthenticated(false);
            });
        } catch (error) {}
      }
    } else {
      const decoded = jwtDecode(token);
      // set bearer token
      Cookies.set("auth", token);
      const baseUrl = `${process.env.REACT_APP_BACKEND_URL}verify-teknisi/${token}`;
      try {
        await axiosJwtPlgn
          .get(baseUrl, {
            withCredentials: true,
          })
          .then((response) => {
            setToken(token);
            setRole(decoded.jabatan);
            setExpired(decoded.exp);
            setIsAuthenticated(true);
            axiosJwtPlgn.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${token}`;
          })
          .catch((error) => {
            setToken(null);
            setRole(null);
            setExpired(null);
            setIsAuthenticated(false);
          });
      } catch (error) {}
    }

    setIsEffectComplete(true);
  };

  if (!isEffectComplete) {
    return null;
  }

  if (requiredRole !== role) {
    return <Navigate to="/" replace />;
  }

  if (isAuthenticated === false) {
    return <Navigate to="/" replace />;
  }

  return children;
};
