import { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Login from "../Page/Login";
import SocialMedia from "../Page/ContactUs";
import AboutUs from "../Page/AboutUs";
import KatalogHarga from "../Page/Admin/Katalog Harga/KatalogHarga";
import AddKatalogHarga from "../Page/Admin/Katalog Harga/AddKatalogHarga";
import EditKatalogHarga from "../Page/Admin/Katalog Harga/EditKatalogHarga";
import AdminSertifikatRayap from "../Page/Admin/Sertifikat_rayap/AdminSertifikatRayap";

import AdminLaporan from "../Page/Admin/Laporan/AdminLaporan";
import AdminLaporanTreatment from "../Page/Admin/Teknisi/AdminLaporanTreatment";

// Admin Kontrak Route
const AdminKontrak = lazy(() => import("../Page/Admin/Kontrak/AdminKontrak"));

// Admin Teknisi Route
const AdminTeknisi = lazy(() => import("../Page/Admin/Teknisi/AdminTeknisi"));

// Admin Penjadwalan Route
const AdminPenjadwalan = lazy(() =>
  import("../Page/Admin/Penjadwalan/AdminPenjadwalan")
);
const AdminAddPenjadwalan = lazy(() =>
  import("../Page/Admin/Penjadwalan/AddPenjadwalan")
);
const AdminEditPenjadwalan = lazy(() =>
  import("../Page/Admin/Penjadwalan/EditPenjadwalan")
);

// Admin Tagihan Route
const AdminTagihan = lazy(() => import("../Page/Admin/Tagihan/AdminTagihan"));

// Admin Pelanggan Route
const AdminPelanggan = lazy(() =>
  import("../Page/Admin/Pelanggan/AdminPelanggan")
);
const AdminAddPelanggan = lazy(() =>
  import("../Page/Admin/Pelanggan/AddPelanggan")
);
const AdminEditPelanggan = lazy(() =>
  import("../Page/Admin/Pelanggan/EditPelanggan")
);

// Admin Pemsanan Route
const AdminPemesanan = lazy(() => import("../Page/Admin/Pemesanan/Pemesanan"));
const AdminEditPemesanan = lazy(() =>
  import("../Page/Admin/Pemesanan/EditPemesanan")
);

// Admin Laporan Bulanan Route
const AdminLaporanBulanan = lazy(() =>
  import("../Page/Admin/Penjadwalan/LaporanBulanan")
);
const AdminLaporanBulananById = lazy(() =>
  import("../Page/Admin/Penjadwalan/LaporanTreatmentByIdPelanggan")
);

// Layanan Kami Route
const PestControl = lazy(() => import("../Page/LayananKami/PestControl"));
const RodentControl = lazy(() => import("../Page/LayananKami/RodentControl"));
const TermiteControl = lazy(() => import("../Page/LayananKami/TermiteControl"));
const Disinfeksi = lazy(() => import("../Page/LayananKami/Disinfeksi"));
const Fumigasi = lazy(() => import("../Page/LayananKami/Fumigasi"));

const AdminProfil = lazy(() => import("../Page/Admin/Profil/AdminProfil"));
const AdminRoutes = () => {
  // checkTokenAndRedirect()
  return (
    <Routes>
      {/* Home Route */}
      <Route path="/contact-us" element={<SocialMedia />} />
      <Route path="/katalog-harga" element={<KatalogHarga />} />
      <Route path="/login" element={<Login />} />
      <Route path="/aboutUs" element={<AboutUs />} />

      {/* Penjadwalan Route */}
      <Route path="/penjadwalan" element={<AdminPenjadwalan />}></Route>
      <Route path="/addPenjadwalan" element={<AdminAddPenjadwalan />}></Route>
      <Route
        path="/editPenjadwalan/:idPenjadwalan"
        element={<AdminEditPenjadwalan />}
      ></Route>

      {/* Pelanggan Route */}
      <Route path="/pelanggan" element={<AdminPelanggan />}></Route>
      <Route path="/addPelanggan" element={<AdminAddPelanggan />}></Route>
      <Route
        path="/editPelanggan/:idPelanggan"
        element={<AdminEditPelanggan />}
      ></Route>

      {/* Tagihan Route */}
      <Route path="/tagihan" element={<AdminTagihan />}></Route>

      {/* Katalog harga Route */}
      <Route path="/addKatalog" element={<AddKatalogHarga />}></Route>
      <Route
        path="/editKatalog/:idKatalog"
        element={<EditKatalogHarga />}
      ></Route>

      {/* Pemesanan Route */}
      <Route path="/pemesanan" element={<AdminPemesanan />}></Route>

      {/* Teknisi Route */}
      <Route path="/teknisi" element={<AdminTeknisi />}></Route>
      <Route
        path="/teknisi/laporanTreatmentTeknisi"
        element={<AdminLaporanTreatment />}
      ></Route>

      {/* Sertifkat Route */}
      <Route
        path="/sertifikat_rayap"
        element={<AdminSertifikatRayap />}
      ></Route>

      {/* Laporan Route */}
      <Route path="/laporan" element={<AdminLaporan />}></Route>

      {/* Profil Route */}
      <Route path="/profil" element={<AdminProfil />}></Route>

      {/* Profil Route */}
      <Route path="/kontrak" element={<AdminKontrak />}></Route>

      {/* Laporan Bulanan Treatment Route */}
      <Route
        path="/laporanBulananTreatment"
        element={<AdminLaporanBulanan />}
      ></Route>
      <Route
        path="/laporanBulananTreatment/:idPelanggan/:bulan/:tahun"
        element={<AdminLaporanBulananById />}
      ></Route>

      {/*Layanan Kami Route */}
      <Route path="/pest-control" element={<PestControl />}></Route>
      <Route path="/rodent-control" element={<RodentControl />}></Route>
      <Route path="/termite-control" element={<TermiteControl />}></Route>
      <Route path="/disinfeksi" element={<Disinfeksi />}></Route>
      <Route path="/fumigasi" element={<Fumigasi />}></Route>
    </Routes>
  );
};

export default AdminRoutes;
