import axios from "axios";
import Cookies from "js-cookie";

const apiBaseUrl = `${process.env.REACT_APP_BACKEND_URL}sertifikat_rayap`;

const getSertifikatById = (id, token) => {
  return axios.get(`${apiBaseUrl}/getData/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const getAllSertifikat = (keyword, page, limit, tanggal_expired, token) => {
  return axios.get(
    `${apiBaseUrl}/getData?search_query=${keyword}&page=${page}&limit=${limit}&tanggal_expired=${tanggal_expired}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      },
    }
  );
};

const addSertifikat = (sertifikat, token) => {
  return axios.post(`${apiBaseUrl}/createData`, sertifikat, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const updateData = (idSertifikat, dataSertifikat, token) => {

  return axios.put(`${apiBaseUrl}/updateData/${idSertifikat}`, dataSertifikat, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteSertifikat = (idSertifikat, token) => {
  return axios.delete(`${apiBaseUrl}/deleteData/${idSertifikat}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

export {
  getSertifikatById,
  getAllSertifikat,
  addSertifikat,
  updateData,
  deleteSertifikat,
};
