import React from "react";
import Navbar from "./Navbar/Navbar";
import "../CSS/Header.css";
import { useLocation } from "react-router-dom";
import NavbarAdmin from "./Navbar/NavbarAdmin";
import NavbarPelanggan from "./Navbar/NavbarPelanggan";


function Headerr() {
  const location = useLocation();
  let NavbarComponent;
  
  if (location.pathname.startsWith("/admin-home")) {
    NavbarComponent = <NavbarAdmin />;
  } else if (location.pathname.startsWith("/pelanggan")) {
    NavbarComponent = <NavbarPelanggan />;
  } else {
    NavbarComponent = <Navbar />;
  }


  return (
    <header className="rounded-bottom-4 border-bottom-1">
      {NavbarComponent}
    </header>
  );
}

export default Headerr;
