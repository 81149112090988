import React from "react";

const NotFound = () => {
return (
    <div className="container text-center">
        <div className="row">
            <div className="col">
                <h1 className="mt-5 text-danger">404 Not Found</h1>
                <p className="mb-5">The page you are looking for does not exist.</p>
            </div>
        </div>
    </div>
);
};

export default NotFound;
