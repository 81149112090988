import React from "react";

const Sectionn = ({ children, className }) => {
  return (
    <article className={`container-fluid mt-5 pb-5 ${className}`}>
      <div className="container">{children}</div>
    </article>
  );
};

export default Sectionn;
