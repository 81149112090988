import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../Assets/Image/Logo.png";
import "../../CSS/Admin/navbar_admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { PersonCircle } from "react-bootstrap-icons";
import Cookies from "js-cookie";
import { logoutUser } from "../../Model/User/userModel";

const NavbarAdmin = () => {
  const handleLogout = () => {
    logoutUser()
      .then((res) => {
        console.log("res" + res.data);
        // Hapus cookie setelah permintaan logout berhasil
        Cookies.remove("_auth_");
        Cookies.remove("role");
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error during logout: ", error);
      });
  };

  return (
    <nav className="navbar navbar-expand-lg ps-5 pe-5 py-2">
      <div className="container-fluid">
        <Link to="/admin-home">
          <img
            src={logo}
            className="navbar-brand img-fluid ms-4 custom-image"
            alt="Logo"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse custom-navbar-collapse justify-content-center"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  color: isActive ? "blue" : "red !important",
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/admin-home"
                end
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/admin-home/aboutUs"
              >
                Tentang Kami
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/admin-home/contact-us"
              >
                Hubungi Kami
              </NavLink>
            </li>
            <li>
              <div class="dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDarkDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Layanan kami
                </a>

                <ul
                  class="dropdown-menu  custom-dropdown-menu"
                  aria-labelledby="navbarDarkDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="/admin-home/pest-control">
                      Pest Control
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/admin-home/rodent-control">
                      Rodent Control
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/admin-home/termite-control">
                      Termite Control
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/admin-home/disinfeksi">
                      Disinfeksi
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/admin-home/fumigasi">
                      Fumigasi
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "500" : "normal",
                })}
                to="/admin-home/katalog-harga"
              >
                Katalog Harga
              </NavLink>
            </li>
            <li className="nav-item dropdown d-lg-none">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Admin
              </a>
              <ul className="dropdown-menu custom-dropdown-menu">
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink className="nav-link" to="/admin-home/penjadwalan">
                    Penjadwalan
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink className="nav-link" to="/admin-home/pelanggan">
                    Pelanggan
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink className="nav-link" to="/admin-home/pemesanan">
                    Prospek Pelanggan
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink className="nav-link" to="/admin-home/teknisi">
                    Teknisi
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink className="nav-link" to="/admin-home/tagihan">
                    Tagihan
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink
                    className="nav-link"
                    to="/admin-home/sertifikat_rayap"
                  >
                    Sertifikat Rayap
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink className="nav-link" to="/admin-home/laporan">
                    Laporan
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink className="nav-link" to="/admin-home/kontrak">
                    Kontrak
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <NavLink className="nav-link" to="/admin-home/profil">
                    Profil
                  </NavLink>
                </li>
                <li className="nav-item d-lg-none d-md-block d-sm-block">
                  <button onClick={handleLogout} className="nav-link">
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="justify-content-end d-lg-block d-md-none d-sm-none d-none position-relative">
          <PersonCircle
            size={35}
            className="dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul
            className="dropdown-menu custom-person-dropdown"
            aria-labelledby="navbarDropdown"
          >
            <li>
              <NavLink className="dropdown-item" to="/admin-home/penjadwalan">
                Penjadwalan
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/admin-home/pelanggan">
                Pelanggan
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/admin-home/pemesanan">
                Prospek Pelanggan
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/admin-home/teknisi">
                Teknisi
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/admin-home/tagihan">
                Tagihan
              </NavLink>
            </li>
            <li>
              <NavLink
                className="dropdown-item"
                to="/admin-home/sertifikat_rayap"
              >
                Sertifikat Rayap
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/admin-home/laporan">
                Laporan
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/admin-home/kontrak">
                Kontrak
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to="/admin-home/profil">
                Profil
              </NavLink>
            </li>
            <li>
              <button onClick={handleLogout} className="dropdown-item">
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarAdmin;
