import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import Sectionn from "../../../Components/Sectionn";
import "../../../CSS/Admin/katalog_harga.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getDataById,
  updateData,
} from "../../../Model/KatalogHarga/katalogHargaModel";
import { AuthContext } from "../../../Routes/AuthContext";

const EditKatalogHarga = () => {
  // AuthContext
  const { token } = useContext(AuthContext);

  const { idKatalog } = useParams();
  const [dataKatalog, setKatalog] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // Fungsi untuk menutup notifikasi
  const closeAlert = () => {
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };

  // Mengambil semua pemesanan berdasarkan id
  useEffect(() => {
    getDataById(idKatalog, token)
      .then((response) => {
        setKatalog(response.data.data);
      })
      .catch((error) => {
        setErrorMsg(error.response.data.errMessage);
      });
  }, []);

  const validasiSchema = Yup.object({
    jenis_layanan: Yup.string().required("Nama Layanan tidak boleh kosong"),
    deskripsi: Yup.string().required("Deskripsi tidak boleh kosong"),
    harga: Yup.string().required(" Harga tidak boleh kosong"),
  });

  const formik = useFormik({
    initialValues: {
      idkatalog_harga: dataKatalog ? dataKatalog.idkatalog_harga : "",
      jenis_layanan: dataKatalog ? dataKatalog.jenis_layanan : "",
      deskripsi: dataKatalog ? dataKatalog.deskripsi : "",
      harga: dataKatalog ? dataKatalog.harga : "",
      minimal_luasan: dataKatalog ? dataKatalog.minimal_luasan : "",
      minimum_cost: dataKatalog ? dataKatalog.minimum_cost : "",
      // fileGambar: null,
    },
    onSubmit: (values, { setSubmitting }) => {
      // Panggil fungsi editPelanggan dengan id pelanggan dan data dari form
      updateData(idKatalog, values, token)
        .then((response) => {
          setShowSuccessAlert(true);
          setTimeout(closeAlert, 4000); // 4000 ms = 4 detik
        })
        .catch((error) => {
          console.error("Terjadi kesalahan saat update data pelanggan", error);
          setShowSuccessAlert(false);
          setShowErrorAlert(true);
          setTimeout(closeAlert, 4000); // 4000 ms = 4 detik
        })
        .finally(() => {
          setSubmitting(false); // Ini akan mengizinkan form disubmit lagi
        });
    },
    validationSchema: validasiSchema,
    enableReinitialize: true,
  });
  return (
    <>
      {showSuccessAlert && (
        <div
          className="alert alert-success position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          Sukses Mengedit Data Katalog Harga !!!
        </div>
      )}

      {showErrorAlert && (
        <div
          className="alert alert-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          {errorMsg}
        </div>
      )}
      <Sectionn>
        <h2 className="text-center mb-5">Edit Katalog Harga</h2>
        <form onSubmit={formik.handleSubmit}>
          <h5>Id Katalog</h5>
          <div>
            <input
              disabled
              name="idkatalog_harga"
              className="form-control"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.idkatalog_harga}
            ></input>

            {formik.touched.jenis_layanan && formik.errors.jenis_layanan ? (
              <div className="text-danger">{formik.errors.jenis_layanan}</div>
            ) : null}
          </div>

          <h5 className="mt-3">Masukkan nama layanan</h5>
          <div>
            <input
              name="jenis_layanan"
              className="form-control"
              placeholder="contoh: Pest Control"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.jenis_layanan}
            ></input>

            {formik.touched.jenis_layanan && formik.errors.jenis_layanan ? (
              <div className="text-danger">{formik.errors.jenis_layanan}</div>
            ) : null}
          </div>

          <h5 className="mt-3">Masukkan deskripsi</h5>
          <div>
            <textarea
              name="deskripsi"
              className="form-control"
              placeholder="contoh: Pengendali hama kecoa"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.deskripsi}
            ></textarea>
            {formik.touched.deskripsi && formik.errors.deskripsi ? (
              <div className="text-danger">{formik.errors.deskripsi}</div>
            ) : null}
          </div>

          <h5 className="mt-3">Masukkan harga</h5>
          <div>
            <input
              name="harga"
              className="form-control"
              placeholder="contoh: 120000"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.harga}
            ></input>
            {formik.touched.harga && formik.errors.harga ? (
              <div className="text-danger">{formik.errors.harga}</div>
            ) : null}
          </div>

          <h5 className="mt-3">Masukkan Minimal Luas</h5>
          <div>
            <input
              name="minimal_luasan"
              className="form-control"
              placeholder="contoh: 100 m2"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.minimal_luasan}
            ></input>
          </div>

          <h5 className="mt-3">Masukkan minimum cost</h5>
          <div>
            <input
              name="minimum_cost"
              className="form-control"
              placeholder="contoh: Pest Control adalah ....."
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.minimum_cost}
            ></input>
          </div>

          <div className="text-center mt-5">
            <button type="submit" className="btn btn-primary">
              Edit
            </button>
          </div>
        </form>
      </Sectionn>
    </>
  );
};

export default EditKatalogHarga;
