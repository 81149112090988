import React from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const ButtonWithLink = ({ href, children, className, sizee }) => {
  return (
    <>
      {/* <a href={href} className={`btn ${className}`}>
        {children}
      </a> */}
      <Button size={sizee} href={href} className={`btn ${className}`}>
        {children}
      </Button>
    </>
  );
};

export default ButtonWithLink;
