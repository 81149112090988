import { useContext } from "react";
import { AuthContext } from "../../Routes/AuthContext";
import axios from "axios";

const axiosJWTPlng = axios.create();

export function useAxiosJwtPlgn() {
  const {
    isAuthenticated,
    role,
    setRole,
    token,
    setToken,
    setIsAuthenticated,
    expired,
    setExpired,
  } = useContext(AuthContext);

  axiosJWTPlng.interceptors.request.use(
    async (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      // get bearer token
      // get bearer token
      const bearerToken = config.headers.Authorization.split(" ")[1];

      const currentTime = Date.now();
      if (expired * 1000 < currentTime) {
        setIsAuthenticated(false);
        setToken(null);
        setRole(null);
        setExpired(null);
      }
      return config;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );

  return axiosJWTPlng;
}
