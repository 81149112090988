import React from "react";
import { Facebook, Instagram, Whatsapp, Youtube } from "react-bootstrap-icons";
import Sectionn from "./Sectionn";

const Footer = (props) => {
  return (
    <footer
      className="text-center text-white bg-dark mb-0 pb-0"
      style={{
        position: "relative",
        left: "0",
        bottom: "0",
        width: "100%",
        minHeight: "auto",
      }}
    >
      <div
        className="col-md-3 col-6 text-md-center text-sm-end text-end mt-4 mt-md-0 "
        style={{
          zIndex: 999,
          position: "fixed",
          right: "1rem",
          bottom: "5rem",
        }}
      >
        <a
          href="https://api.whatsapp.com/send/?phone=6287855170409&text=Hallo&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
          className="d-block"
          style={{
            textDecoration: "none",
          }}
        >
          <Whatsapp className="icon whatsapp fw-bold" size={30} color="green" />
          <span className="d-block fs-6 text-danger fw-bold">Wa kami !</span>
        </a>
      </div>
      <div className="container p-4">
        <section className="">
          <div className="row mt-5 text-md-center">
            <div className="col-md-3 col-6 social-media-icons text-md-center text-sm-end text-end">
              <a
                href="https://www.facebook.com/hery.bondet?mibextid=2JQ9oc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook className="icon w-50" size={40} color="white" />
              </a>
            </div>
            <div className="col-md-3 col-6">
              <a
                href="https://www.instagram.com/jasabasmi_hama_serangga?utm_source=qr&igsh=ZWU1anNnazBsenBy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram className="icon w-50" size={40} color="white" />
              </a>
            </div>
            <div className="col-md-3 col-6 text-md-center text-sm-end text-end mt-4 mt-md-0">
              <a
                href="https://api.whatsapp.com/send/?phone=6287855170409&text=Hallo&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Whatsapp
                  className="icon whatsapp w-50"
                  size={40}
                  color="white"
                />
              </a>
            </div>
            <div className="col-md-3 col-6 mt-4 mt-md-0">
              <a
                href="https://youtube.com/@jasa_pembasmihama_serangga7965?si=9aIdxTnBtBJrpCKi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Youtube
                  className="icon youtube w-50"
                  size={40}
                  color="white"
                />
              </a>
            </div>
          </div>
        </section>
      </div>
      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2024 TATA SINAR GEMILANG
      </div>
      <p className="fs-6 mt-2">CONTAC US - 085335850720 (WA)</p>
      <p className="fs-6 mt-2">No Telp - 031 8535052</p>

      <h5 className="d-flex align-items-center justify-content-center text-center">
        <span className="me-1">
          {/* Ganti ms-1 dengan me-2 untuk mengatur jarak */}
          {/* <GeoAlt></GeoAlt> */}
        </span>
        Lokasi
      </h5>
      <a
        className="p-0 text-white "
        href="https://maps.app.goo.gl/QHFYvRPRwDw3vTX19"
      >
        Gg.Perintis, Keboan Anom, Gedangan, Sidoarjo, Jawa Timur.
      </a>

      <h5 className="mt-2 py-2">
        <span className="me-1">
          {/* Ganti ms-1 dengan me-2 untuk mengatur jarak */}
          {/* <Globe></Globe> */}
        </span>
        Unit Pelayanan
      </h5>
      <p>Jawa Timur - Indonesia</p>
    </footer>
  );
};

export default Footer;
