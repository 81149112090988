export const itemIdPelanggan = [
  { value: "TTSG-sda-001", label: "TTSG-sda-001" },
  { value: "TTSG-sda-002", label: "TTSG-sda-002" },
  { value: "TTSG-sby-003", label: "TTSG-sby-003" },
  { value: "TTSG-sby-004", label: "TTSG-sby-004" },
  { value: "TTSG-sda-005", label: "TTSG-sda-005" },
  { value: "TTSG-sda-006", label: "TTSG-sda-006" },
  { value: "TTSG-sda-007", label: "TTSG-sda-007" },
  { value: "TTSG-sda-008", label: "TTSG-sda-008" },
  { value: "TTSG-sby-009", label: "TTSG-sby-009" },
  { value: "TTSG-sby-010", label: "TTSG-sby-010" },
  { value: "TTSG-sda-011", label: "TTSG-sda-011" },
  { value: "TTSG-sda-012", label: "TTSG-sda-012" },
  { value: "TTSG-sby-013", label: "TTSG-sby-013" },
  { value: "TTSG-sda-014", label: "TTSG-sda-014" },
  { value: "TTSG-sda-015", label: "TTSG-sda-015" },
  { value: "TTSG-sda-016", label: "TTSG-sda-016" },
  { value: "TTSG-sda-017", label: "TTSG-sda-017" },
  { value: "TTSG-sby-018", label: "TTSG-sby-018" },
  { value: "TTSG-sby-019", label: "TTSG-sby-019" },
  { value: "TTSG-kdr-020", label: "TTSG-kdr-020" },
  { value: "TTSG-mjk-021", label: "TTSG-mjk-021" },
  { value: "TTSG-mlng-023", label: "TTSG-mlng-023" },
  { value: "TTSG-sby-024", label: "TTSG-sby-024" },
  { value: "TTSG-sby-025", label: "TTSG-sby-025" },
  { value: "TTSG-sby-026", label: "TTSG-sby-026" },
  { value: "TTSG-sda-027", label: "TTSG-sda-027" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
];

export const filteritem = [
  { value: "all", label: "ALL" },
  { value: "teknisi", label: "Teknisi" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
  { value: "", label: "" },
  // Tambahkan item lainnya di sini
];

export const statusTagihan = [
  // { value: "Pilih", label: "Pilih menu" },
  { value: "belum lunas", label: "belum lunas" },
  { value: "lunas", label: "lunas" },
];

export const itemStatusKontrak = [
  // { value: "Pilih", label: "Pilih menu" },
  { value: "tidak aktif", label: "tidak aktif" },
  { value: "aktif", label: "aktif" },
];

export const statusPenjadwalan = [
  // { value: "Pilih", label: "Pilih menu" },
  { value: "belum selesai", label: "belum selesai" },
  { value: "selesai", label: "selesai" },
];

export const listBulan = [
  { value: "1", label: "Januari" },
  { value: "2", label: "Februari" },
  { value: "3", label: "Maret" },
  { value: "4", label: "April" },
  { value: "5", label: "Mei" },
  { value: "6", label: "Juni" },
  { value: "7", label: "Juli" },
  { value: "8", label: "Agustus" },
  { value: "9", label: "September" },
  { value: "10", label: "Oktober" },
  { value: "11", label: "November" },
  { value: "12", label: "Desember" },
];

{
  /* <option selected>Periode Kunjungan</option>
<option value="1x / bulan">1x / bulan</option>
<option value="2x / bulan">2x / bulan</option>
<option value="1x / minggu">1x / minggu</option>
<option value="permintaan">permintaan</option>
<option value="station">station</option> */
}
export const itemPeriodeKontrak = [
  { value: "1x / bulan", label: "1x / bulan" },
  { value: "2x / bulan", label: "2x / bulan" },
  { value: "1x / minggu", label: "1x / minggu" },
  { value: "permintaan", label: "permintaan" },
  { value: "station", label: "station" },
];

export const itemJenisPekerjaan = [
  { value: "Pest Control", label: "Pest Control" },
  { value: "Rodent Control", label: "Rodent Control" },
  { value: "Termite Control", label: "Termite Control" },
  { value: "Disinfeksi", label: "Disinfeksi" },
  { value: "Fumigasi", label: "Fumigasi" },
  { value: "Dll", label: "Dll" },
];

export const statusItems = [
  // { value: "Pilih", label: "Pilih menu" },
  { value: "belum selesai", label: "belum selesai" },
  { value: "selesai", label: "selesai" },
];

export const itemIdTeknisi = [
  // { value: "Pilih", label: "Pilih menu" },
  { value: "TTSG-tkns-001", label: "TTSG-tkns-001" },
  { value: "TTSG-tkns-002", label: "TTSG-tkns-002" },
];

// Daftar bulan dalam setahun
export const months = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
  "",
  "",
  "",
  "",
  "",
];
