import axios from "axios";
import Cookies from "js-cookie";

const apiBaseUrl = `${process.env.REACT_APP_BACKEND_URL}laporan`;

const getLaporanById = (id, token) => {
  // const token = Cookies.get("_auth_");
  return axios.get(`${apiBaseUrl}/getData/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const getAllLaporan = (keyword, page, limit, tanggal_laporan, token) => {
  // const token = Cookies.get("_auth_");
  return axios.get(
    `${apiBaseUrl}/getData?search_query=${keyword}&page=${page}&limit=${limit}&tanggal_laporan=${tanggal_laporan}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      },
    }
  );
};

const addLaporan = (laporan, token) => {
  // const token = Cookies.get("_auth_");
  return axios.post(`${apiBaseUrl}/createData`, laporan, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const updateData = (idLaporan, dataLaporan, token) => {
  // const token = Cookies.get("_auth_");
  return axios.put(`${apiBaseUrl}/updateData/${idLaporan}`, dataLaporan, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteLaporan = (idLaporan, token) => {
  // const token = Cookies.get("_auth_");
  return axios.delete(`${apiBaseUrl}/deleteData/${idLaporan}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

export { getLaporanById, getAllLaporan, addLaporan, updateData, deleteLaporan };
