import React, { useState, useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Cookies from "js-cookie";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useAxiosJwt } from "../Model/Config/axiosConfig";
import { loginUser } from "../Model/User/userModel";

export const ProtectedRoute = ({ children, requiredRole }) => {
  const [tokenValidation, setTokenValidation] = useState(false);
  const [isEffectComplete, setIsEffectComplete] = useState(false);
  const axiosJwt = useAxiosJwt();

  // Param Url
  const queryparam = new URLSearchParams(useLocation().search);
  const idCustomer = queryparam.get("idCustomer");
  const tokenparam = queryparam.get("token");

  const {
    isAuthenticated,
    role,
    setRole,
    token,
    setToken,
    setIsAuthenticated,
    expired,
    setExpired,
  } = useContext(AuthContext);

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axiosJwt.get(
        process.env.REACT_APP_BACKEND_URL + "refreshToken",
        {
          withCredentials: true,
        }
      );
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setRole(decoded.jabatan);
      setExpired(decoded.exp);
      setIsAuthenticated(true);
    } catch (error) {}
    setIsEffectComplete(true);
  };

  if (!isEffectComplete) {
    return null;
  }

  if (requiredRole !== role) {
    return <Navigate to="/" replace />;
  }

  if (isAuthenticated === false) {
    Cookies.remove("auth");
    Cookies.remove("role");
    return <Navigate to="/" replace />;
  }

  return children;
};
