import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../CSS/card.css";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { deleteData } from "../../Model/KatalogHarga/katalogHargaModel";
import ModalDelete from "../ModalDelete";
import { AuthContext } from "../../Routes/AuthContext";
const CardActionLocation = () => {
  <>;</>;
};

export default function Cardd({
  title,
  harga,
  deskripsi,
  luas,
  minimum_cost,
  idKatalog,
  setTriggerFetch,
}) {
  const { token } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setShowModal(true);
    console.log(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = () => {
    deleteData(idKatalog, token)
      .then((response) => {
        setShowModal(false);
        setTriggerFetch((prev) => !prev);
        // Set status di localStorage
        localStorage.setItem("deleteStatus", "success"); // Fungsi untuk merefresh halaman
      })
      .catch((error) => {
        // Set status di localStorage
        localStorage.setItem("deleteStatus", "error");
        console.error("Error deleting pelanggan:", error);
        // refreshPage();
      });
  };

  const location = useLocation();
  const currentLocation = location.pathname;
  let cardAction;
  console.log(currentLocation);
  if (currentLocation.startsWith("/admin-home")) {
    cardAction = <CardActionLocation />;
  } else {
    cardAction = null;
  }
  return (
    <div className="container-fluid mb-3 col-md-6 mx-auto">
      <div className="card">
        <h3 className="card-header">{title}</h3>
        <div className="card-body">
          <h5 className="mb-4">{deskripsi}</h5>
          <p className="mb-1">Harga {harga}</p>
          <p className="mb-1">Minimum Cost {minimum_cost}</p>
          <p className="mb-1">Minimal Luasan {luas}</p>
          <div className="d-flex flex-row-reverse">
            <Link
              to={`/admin-home/editKatalog/${idKatalog}`}
              className="btn btn-warning btn-sm me-2"
            >
              Edit
            </Link>
            <a
              className="btn btn-danger btn-sm me-2"
              onClick={() => handleDeleteClick(idKatalog)}
            >
              Delete
            </a>
          </div>

          <ModalDelete
            commandText={"Apakah Anda yakin ingin menghapus data ini?"}
            showModal={showModal}
            onConfirm={handleConfirmDelete}
            onCLose={handleCloseModal}
          ></ModalDelete>
        </div>
      </div>
    </div>
  );
}
