import * as React from "react";

import "../../CSS/card.css";

import { Button, CardActionArea, CardActions, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export default function CardOtherAdmin({
  title,
  harga,
  deskripsi,
  luas,
  minimum_cost,
}) {
  return (
    <div className="container mb-3 col-md-6 mx-auto">
      <div className="card">
        <h3 className="card-header">{title}</h3>
        <div className="card-body">
          <h5 className="mb-4">{deskripsi}</h5>
          <p className="mb-1">Harga {harga}</p>
          <p className="mb-1">Minimum Cost {minimum_cost}</p>
          <p className="mb-1">Minimal Luasan {luas}</p>
        </div>
      </div>
    </div>
  );
}
