import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

async function loginUser(username, password) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/login`,
      {
        username: username,
        password: password,
      },
      {
        withCredentials: true,
      }
    );
    const decode = jwtDecode(response.data.token);
    const expired = decode.exp;
    return { ...response.data, expired: expired };
  } catch (error) {
    throw error.response.data.errMessage;
  }
}

const logoutUser = () => {
  return axios.delete(process.env.REACT_APP_BACKEND_URL + "logout", {
    withCredentials: true,
  });
};

export { loginUser, logoutUser };
