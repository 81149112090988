import { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Login from "../Page/Login";
import SocialMedia from "../Page/ContactUs";
import AboutUs from "../Page/AboutUs";
import KatalogHarga from "../Page/Admin/Katalog Harga/KatalogHarga";

// Layanan Kami Route
const PestControl = lazy(() => import("../Page/LayananKami/PestControl"));
const RodentControl = lazy(() => import("../Page/LayananKami/RodentControl"));
const TermiteControl = lazy(() => import("../Page/LayananKami/TermiteControl"));
const Disinfeksi = lazy(() => import("../Page/LayananKami/Disinfeksi"));
const Fumigasi = lazy(() => import("../Page/LayananKami/Fumigasi"));

// Pelanggan Penjadwalan Route
const PelangganPenjadwalan = lazy(() =>
  import("../Page/Pelanggan/PelangganPenjadwalan")
);

//
const PelangganTagihan = lazy(() =>
  import("../Page/Pelanggan/PelangganTagihan")
);

const PelangganSertifikat = lazy(() =>
  import("../Page/Pelanggan/PelangganSertifikat")
);

const PelangganLaporan = lazy(() =>
  import("../Page/Pelanggan/PelangganLaporan")
);

const PelangganProfil = lazy(() => import("../Page/Pelanggan/PelangganProfil"));
const PelangganRoute = () => {
  return (
    <Routes>
      {/* Home Route */}
      <Route path="/contact-us" element={<SocialMedia />} />
      <Route path="/katalog-harga" element={<KatalogHarga />} />
      <Route path="/login" element={<Login />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      {/* Penjadwalan Route */}
      <Route path="/penjadwalan" element={<PelangganPenjadwalan />}></Route>

      {/* Tagihan Route */}
      <Route path="/tagihan" element={<PelangganTagihan />}></Route>

      {/* Sertifikat Route */}
      <Route path="/sertifikat_rayap" element={<PelangganSertifikat />}></Route>

      {/* Laporan Route */}
      <Route path="/laporan" element={<PelangganLaporan />}></Route>

      {/* Profil Route */}
      <Route path="/profil" element={<PelangganProfil />}></Route>

      {/*Layanan Kami Route */}
      <Route path="/pest-control" element={<PestControl />}></Route>
      <Route path="/rodent-control" element={<RodentControl />}></Route>
      <Route path="/termite-control" element={<TermiteControl />}></Route>
      <Route path="/disinfeksi" element={<Disinfeksi />}></Route>
      <Route path="/fumigasi" element={<Fumigasi />}></Route>
    </Routes>
  );
};

export default PelangganRoute;
