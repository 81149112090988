import { lazy } from "react";
import { Routes, Route } from "react-router-dom";

// Teknisi Penjadwalan Route
const TeknisiPenjadwalan = lazy(() =>
  import("../Page/Teknisi/TeknisiPenjadwalan")
);

const PelangganProfil = lazy(() => import("../Page/Pelanggan/PelangganProfil"));
const TeknisiRoute = () => {
  return (
    <Routes>
      {/* Home Route */}
      <Route path="/penjadwalan" element={<TeknisiPenjadwalan />} />
    </Routes>
  );
};

export default TeknisiRoute;
