import React, { useEffect, useState, useRef, useContext } from "react";
import Sectionn from "../../../Components/Sectionn";
import "../../../CSS/Admin/katalog_harga.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createData } from "../../../Model/KatalogHarga/katalogHargaModel";
import { AuthContext } from "../../../Routes/AuthContext";

const AddKatalogHarga = () => {
  //AuthContext
  const { token } = useContext(AuthContext);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false); // State untuk notifikasi gagal

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  // Fungsi untuk menutup notifikasi
  const closeAlert = () => {
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };
  const validasiSchema = Yup.object({
    jenis_layanan: Yup.string().required("Nama Layanan tidak boleh kosong"),
    deskripsi: Yup.string().required("Deskripsi tidak boleh kosong"),
    harga: Yup.string().required(" Harga tidak boleh kosong"),
    minimal_luasan: Yup.string().required(" Minimal Luasan tidak boleh kosong"),
    minimum_cost: Yup.string().required(" Minimum Cost tidak boleh kosong"),
  });

  const formik = useFormik({
    initialValues: {
      jenis_layanan: "",
      deskripsi: "",
      harga: "",
      minimal_luasan: "",
      minimum_cost: "",
      // fileGambar: null,
    },
    onSubmit: (values, { setSubmitting }) => {
      // Memanggil fungsi addPelanggan dengan data form

      createData(values, token)
        .then((response) => {
          // Handle sukses, misal menampilkan notifikasi atau redirect
          setShowSuccessAlert(true);
          setTimeout(closeAlert, 4000); // 4000 ms = 4 detik
        })
        .catch((error) => {
          // Handle gagal, misal menampilkan notifikasi error
          console.error("Gagal menambahkan pelanggan", error);
          setErrorMsg(error.response.data.errMessage);
          setShowSuccessAlert(false);
          setShowErrorAlert(true);
          setTimeout(closeAlert, 4000); // 4000 ms = 4 detik
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema: validasiSchema,
  });
  return (
    <>
      {showSuccessAlert && (
        <div
          className="alert alert-success position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          Sukses Menambah Data !!!
        </div>
      )}

      {showErrorAlert && (
        <div
          className="alert alert-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          {errorMsg}
        </div>
      )}
      <Sectionn>
        <h2 className="text-center mb-5">Add Katalog Harga</h2>
        <form onSubmit={formik.handleSubmit}>
          <h5>Masukkan nama layanan</h5>
          <div>
            <input
              name="jenis_layanan"
              className="form-control"
              placeholder="contoh: Pest Control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
            ></input>

            {formik.touched.jenis_layanan && formik.errors.jenis_layanan ? (
              <div className="text-danger">{formik.errors.jenis_layanan}</div>
            ) : null}
          </div>

          <h5 className="mt-3">Masukkan deskripsi</h5>
          <div>
            <textarea
              name="deskripsi"
              className="form-control"
              placeholder="contoh: Pengendali hama kecoa"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            {formik.touched.deskripsi && formik.errors.deskripsi ? (
              <div className="text-danger">{formik.errors.deskripsi}</div>
            ) : null}
          </div>

          <h5 className="mt-3">Masukkan harga</h5>
          <div>
            <input
              name="harga"
              className="form-control"
              placeholder="contoh: 120000"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></input>
            {formik.touched.harga && formik.errors.harga ? (
              <div className="text-danger">{formik.errors.harga}</div>
            ) : null}
          </div>

          <h5 className="mt-3">Masukkan Minimal Luas</h5>
          <div>
            <input
              name="minimal_luasan"
              className="form-control"
              placeholder="contoh: 100 m2"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></input>
            {formik.touched.minimal_luasan && formik.errors.minimal_luasan ? (
              <div className="text-danger">{formik.errors.minimal_luasan}</div>
            ) : null}
          </div>

          <h5 className="mt-3">Masukkan minimum cost</h5>
          <div>
            <input
              name="minimum_cost"
              className="form-control"
              placeholder="contoh: Pest Control adalah ....."
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></input>
            {formik.touched.minimum_cost && formik.errors.minimum_cost ? (
              <div className="text-danger">{formik.errors.minimum_cost}</div>
            ) : null}
          </div>

          <div className="text-center mt-5">
            <button type="submit" className="btn btn-outline-primary w-25">
              ADD
            </button>
          </div>
        </form>
      </Sectionn>
    </>
  );
};

export default AddKatalogHarga;
