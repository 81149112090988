import axios from "axios";
import Cookies from "js-cookie";
import { useContext } from "react";
import { AuthContext } from "../../Routes/AuthContext";
import { useAxiosJwt } from "../Config/axiosConfig";
// URL dasar API, sesuaikan dengan konfigurasi server Anda
const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}katalog_harga`; // Contoh URL
// const { token } = useContext(AuthContext);

const getDataById = (id, token) => {
  // const token = Cookies.get("_auth_");
  return axios.get(`${BASE_URL}/getData/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// Fungsi untuk mendapatkan data katalog_harga
const getData = (token) => {
  const tokenCookie = Cookies.get("_auth_");

  return axios.get(`${BASE_URL}/getData`);
};

// Fungsi untuk menambahkan data katalog_harga
const createData = (data, token) => {
  // const token = Cookies.get("_auth_");
  return axios.post(`${BASE_URL}/createData`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Fungsi untuk mengedit data katalog_harga
const updateData = (id, data, token) => {
  // const token = Cookies.get("_auth_");
  return axios.put(`${BASE_URL}/updateData/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Fungsi untuk menghapus data katalog_harga
const deleteData = (id, token) => {
  // const token = Cookies.get("_auth_");
  return axios.delete(`${BASE_URL}/deleteData/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export { getData, getDataById, createData, updateData, deleteData };
