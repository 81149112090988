import React, { useState, useEffect, cloneElement, useContext } from "react";
import "../../../CSS/Admin/penjadwalan.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { statusTagihan } from "../../../Utils/items";
import "../../../CSS/Admin/pelanggan.css";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { addSertifikat } from "../../../Model/SertifikatRayap/sertifikatRayapModel";
import { getDataPenjadwalan } from "../../../Model/PenjadwalanModel/penjadwalanModel";
import { AuthContext } from "../../../Routes/AuthContext";

const AdminAddSertifikatRayap = ({ show, onHide, setTrigger }) => {
  const { token } = useContext(AuthContext);

  // Handle alert
  const [errorMessages, setErrorMessages] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  // State untuk menampung data pelanggan
  // Tambahkan state ini
  const [selectedOption, setSelectedOption] = useState(null);
  const [dataPenjadwalan, setDataPenjadwalan] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  // Membuat array dari nilai 'value' dalam idPelanggan
  const idPenjadwalanValues = dataPenjadwalan.map((item) => item.idpenjadwalan);
  const statussItemsList = statusTagihan.map((item) => item.value);
  //variable react select
  const option = dataPenjadwalan
    ? dataPenjadwalan.map((item) => ({
        value: item.idpenjadwalan,
        label: item.idpenjadwalan + `  (${item.nama_pelanggan})`,
      }))
    : [];
  // Fungsi untuk menutup notifikasi
  const closeAlert = () => {
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };
  const validasiSchema = Yup.object({
    idpenjadwalan: Yup.string().required("ID Penjadwalan tidak boleh kosong"),
    tanggal_expired: Yup.date().required("Tanggal expired tidak boleh kosong"),
    file_sertifikat: Yup.mixed()
      .required("File Sertifikat tidak boleh kosong")
      .test(
        "fileSize",
        "Ukuran file tidak boleh lebih dari 5 Mb",
        (value) => value && value.size <= 5 * 1024 * 1024 // 5 MB
      )
      .test(
        "fileFormat",
        "Format file tidak didukung",
        (value) =>
          value &&
          ["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(
            value.type
          )
      ),
  });
  useEffect(() => {
    getDataPenjadwalan(token)
      .then((response) => {
        setDataPenjadwalan(response.data.data);
      })
      .catch((error) => {
        setErrMessage(error.response.data.errMessage);
      });
  }, []);
  const formik = useFormik({
    initialValues: {
      idsertifikat_rayap: "",
      idpenjadwalan: "",
      nama_pelanggan: "",
      tanggal: "",
      alamat: "",
      file_sertifikat: "",
      nama_file_sertifikat: "",
      tanggal_expired: "",
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // Memanggil fungsi addPelanggan dengan data form
      const formData = new FormData();
      formData.append("idpenjadwalan", values.idpenjadwalan);
      formData.append("file_sertifikat", values.file_sertifikat);
      formData.append("tanggal_expired", values.tanggal_expired);
      addSertifikat(formData, token)
        .then((response) => {
          if (response.data.status === "gagal") {
            setShowErrorAlert(true);
            setErrMessage(response.data.errMessage);
            setTimeout(closeAlert, 1000);
            resetForm();
            return;
          }
          resetForm();
          setSubmitting(false);
          setTrigger((prev) => !prev);
          setShowSuccessAlert(true);
          setTimeout(closeAlert, 1000); // 4000 ms = 4 detik
        })
        .catch((error) => {
          if (error.response.data.errMessage) {
            setErrMessage(error.response.data.errMessage);
          } else {
            setErrMessage("Internal Server Error");
          }

          setShowErrorAlert(true);
          setTimeout(closeAlert, 3000); // 4000 ms = 4 detik
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema: validasiSchema,
  });

  return (
    <div>
      {showSuccessAlert && (
        <div
          className="alert alert-success border-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
          style={{ zIndex: 9999 }}
        >
          Data Sertifikat berhasil Ditambah
        </div>
      )}
      {showErrorAlert && (
        <div
          className="alert alert-danger border-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
          style={{ zIndex: 9999 }}
        >
          {errMessage}
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Modal show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Add Sertifikat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-3">
              <label>Id Penjadwalan:</label>
              <Select
                name="idpenjadwalan"
                placeholder="Pilih Id Penjadwalan"
                options={option}
                value={selectedOption}
                onChange={(selectedOption) => {
                  formik.setFieldValue("idpenjadwalan", selectedOption.value);
                  setSelectedOption(selectedOption); // tambahkan ini
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.idpenjadwalan && formik.errors.idpenjadwalan ? (
                <div className="text-danger">{formik.errors.idpenjadwalan}</div>
              ) : null}
            </div>

            <div className="form-group mb-3">
              <label>Tanggal Expired</label>
              <input
                name="tanggal_expired"
                type="date"
                placeholder="Masukkan Tanggal Expired"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // onChange={(e) => setjamMulai(e.target.value)}
              />
              {formik.touched.tanggal_expired &&
              formik.errors.tanggal_expired ? (
                <div className="text-danger">
                  {formik.errors.tanggal_expired}
                </div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label>Masukkan File Sertifikat : </label>
              <span className="text-muted d-block mb-1">
                *pdf, jpg, jpeg, png, Max file 5 mb
              </span>
              <input
                name="file_sertifikat"
                type="file"
                className="form-control"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(
                    "file_sertifikat",
                    event.currentTarget.files[0]
                  );
                }}
                // value={jamMulai}
                // onChange={(e) => setjamMulai(e.target.value)}
              />
              {formik.values.file_sertifikat && (
                <div className="mt-2">
                  <strong>File yang telah diunggah:</strong>{" "}
                  <a
                    href={formik.values.file_sertifikat}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formik.values.nama_file_sertifikat ||
                      "Tidak ada file yang diunggah"}
                  </a>
                </div>
              )}
              {formik.touched.file_sertifikat &&
              formik.errors.file_sertifikat ? (
                <div className="text-danger">
                  {formik.errors.file_sertifikat}
                </div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={formik.handleSubmit}>
              Add Data
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default AdminAddSertifikatRayap;
