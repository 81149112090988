import Headerr from "../Components/Headerr";
import aboutUsFoto from "../Assets/Image/AboutUs/aboutUsFoto1.jpg";
import ButtonWithLink from "../Components/ButtonWithLink";
import { useEffect, useState } from "react";
import "../CSS/aboutUs.css";
import { useLocation } from "react-router-dom";
// Background Foto

import termiteFoto from "../Assets/Image/AboutUs/termiteFoto.jpg";
import disinfektanFoto from "../Assets/Image/AboutUs/disinfektanFoto.jpg";
import pestControlFoto from "../Assets/Image/AboutUs/pestControlFoto.jpg";
import rodentFoto from "../Assets/Image/AboutUs/rodentFoto.jpg";
// Layanan Foto Pest COntrol
import pest1 from "../Assets/Image/AboutUs/PestControl/pest1.jpg";
import pest2 from "../Assets/Image/AboutUs/PestControl/pest2.jpg";
import pest3 from "../Assets/Image/AboutUs/PestControl/pest3.jpg";
import pest4 from "../Assets/Image/AboutUs/PestControl/pest4.jpg";
import pest5 from "../Assets/Image/AboutUs/PestControl/pest5.jpg";
import pest6 from "../Assets/Image/AboutUs/PestControl/pest6.jpg";
// Layanan Foto Disinfeksi
import disfeksi1 from "../Assets/Image/AboutUs/Disinfeksi/disfeksi1.jpg";
import disfeksi2 from "../Assets/Image/AboutUs/Disinfeksi/disfeksi2.jpg";
import disfeksi3 from "../Assets/Image/AboutUs/Disinfeksi/disfeksi3.jpg";
import disfeksi4 from "../Assets/Image/AboutUs/Disinfeksi/disfeksi4.jpg";
import disfeksi5 from "../Assets/Image/AboutUs/Disinfeksi/disfeksi5.jpg";

// Layanan Foto Rodent
import rodent1 from "../Assets/Image/AboutUs/Rodent/rodent1.jpg";
import rodent2 from "../Assets/Image/AboutUs/Rodent/rodent2.jpg";
import rodent3 from "../Assets/Image/AboutUs/Rodent/rodent3.jpg";
import rodent4 from "../Assets/Image/AboutUs/Rodent/rodent4.jpg";
import rodent5 from "../Assets/Image/AboutUs/Rodent/rodent5.jpg";

// Layanan Foto Termite
import termite1 from "../Assets/Image/AboutUs/Termite/termite1.jpg";
import termite2 from "../Assets/Image/AboutUs/Termite/termite2.jpg";
import termite3 from "../Assets/Image/AboutUs/Termite/termite3.jpg";
import termite4 from "../Assets/Image/AboutUs/Termite/termite4.jpg";
import termite5 from "../Assets/Image/AboutUs/Termite/termite5.jpg";
import termite6 from "../Assets/Image/AboutUs/Termite/termite6.jpg";

import "react-bootstrap-icons";

import ImageComponent from "../Components/Image.js";
import {
  ArrowUpCircle,
  Facebook,
  Geo,
  GeoAlt,
  Globe,
  Instagram,
  Whatsapp,
  Youtube,
} from "react-bootstrap-icons";
import Sectionn from "../Components/Sectionn";
import Footer from "../Components/Footer.js";

const images = [
  {
    id: 1,
    image: disinfektanFoto,
    service: "Disinfektan",
    deskripsi: "Jasa Membasmi virus & mikroorganisme",
  },
  {
    id: 2,
    image: pestControlFoto,
    service: "Pest Control",
    deskripsi: "Jasa Membasmi hama serangga",
  },
  {
    id: 3,
    image: termiteFoto,
    service: "Termite control",
    deskripsi: "Jasa Membasmi hama rayap",
  },
  {
    id: 4,
    image: rodentFoto,
    service: "Rodent control",
    deskripsi: "Jasa Membasmi hama tikus",
  }, // Anda bisa menambahkan layanan lain di sini
];
const LayananImagePestControl = [
  {
    id: 1,
    image: pest1,
  },
  {
    id: 2,
    image: pest2,
  },
  {
    id: 3,
    image: pest3,
  },
  {
    id: 4,
    image: pest4,
  },
  {
    id: 5,
    image: pest5,
  },
  {
    id: 6,
    image: pest6,
  },
];

const LayananImageDisinfeksi = [
  {
    id: 1,
    image: disfeksi1,
  },
  {
    id: 2,
    image: disfeksi2,
  },
  {
    id: 3,
    image: disfeksi3,
  },
  {
    id: 4,
    image: disfeksi4,
  },
  {
    id: 5,
    image: disfeksi5,
  },
];

const LayananImageRodent = [
  {
    id: 1,
    image: rodent1,
  },
  {
    id: 2,
    image: rodent2,
  },
  {
    id: 3,
    image: rodent3,
  },
  {
    id: 4,
    image: rodent4,
  },
  {
    id: 5,
    image: rodent5,
  },
];

const LayananImageTermite = [
  {
    id: 1,
    image: termite1,
  },
  {
    id: 2,
    image: termite2,
  },
  {
    id: 3,
    image: termite3,
  },
  {
    id: 4,
    image: termite4,
  },
  {
    id: 5,
    image: termite5,
  },
  {
    id: 6,
    image: termite6,
  },
];
const AboutUs = () => {
  // Check location path
  const location = useLocation();
  const currentPath = location.pathname;
  const contactUsPath =
    currentPath === "/aboutUs" ? "/contact-us" : "/pelanggan/contact-us";

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    console.log("About Us Page Loaded");
    console.log(`Current Path: ${currentPath}`);
    console.log(`Contact Us Path: ${contactUsPath}`);
  }, []);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <div>
      <div
        className="col-md-3 col-6 text-md-center text-sm-end text-end mt-4 mt-md-0 "
        style={{
          zIndex: 999,
          position: "fixed",
          right: "1rem",
          bottom: "5rem",
        }}
      >
        <a
          href="https://wa.me/c/6287855170409"
          target="_blank"
          rel="noopener noreferrer"
          className="d-block"
          style={{
            textDecoration: "none",
          }}
        >
          <Whatsapp className="icon whatsapp fw-bold" size={30} color="green" />
          <span className="d-block fs-6 text-danger fw-bold">Wa kami !</span>
        </a>
      </div>
      <ArrowUpCircle
        size={30}
        color="#353CFF"
        className=""
        onClick={scrollTop}
        style={{
          zIndex: 999,
          height: 40,
          display: showScroll ? "flex" : "none",
          position: "fixed", // Add this
          bottom: "1rem", // Add this
          right: "1rem", // Add this
        }}
      ></ArrowUpCircle>

      <Headerr></Headerr>

      <Sectionn className={"section1 "}>
        <div className="row text-center">
          <div className="col-md-6 d-flex align-items-center">
            <div className="text-start">
              <h1 className="text-primary">Siapa Kami ?</h1>
              <p className="">
                Perusahaan Tata Sinar Gemilang telah berdiri sejak tahun 2012,
                perusahaan ini memiliki beberapa layanan diantaranya Pest
                control, Disinfektan, Rodent Control,Termite control, Fumigasi
                dan General Pest Management. Didukung tenaga terlatih yang
                profesional dan berpengalaman dibidangnya yang mampu memberikan
                pelayanan terbaik, memuaskan dengan kualitas yang memadai dalam
                menangani tugas dan tanggung jawab.
              </p>
              <ButtonWithLink
                className={"text-start fw-bold bg-warning border pesan mt-md-4"}
                href={contactUsPath}
              >
                Info Lebih lanjut
              </ButtonWithLink>
              <br></br>

              <h6 className="mt-2 fs-5 text-primary fw-bold">
                Konsultasi, Survei & Estimasi{" "}
                <span className="text-danger display d-block">*GRATIS</span>
              </h6>
            </div>
          </div>
          <div className="col-md-6 mt-5 mt-lg-0">
            <div className="">
              <ImageComponent
                alt="Jasa Pest Control"
                src={aboutUsFoto}
                className={"img-fluid rounded headshot headshot-1"}
              ></ImageComponent>
            </div>
          </div>
        </div>
      </Sectionn>
      <Sectionn className={"section2 bg-info bg-opacity-50 "}>
        <h2 className="text-center pt-4 title-service">Jasa Pelayanan Kami</h2>
        <hr class="pembatas" />
        <div className="row">
          <h5 className="fs-3 text-danger text-family">Pest Control</h5>
          <h6 className="text-primary fw-bold">
            (Pengendalian Hama Kecoak, Nyamuk, Semut, Ulat, Tomcat, Kamitetep,
            DLL)
          </h6>
          <div className="d-flex Layanan flex-wrap">
            {LayananImagePestControl.map((item, index) => (
              <ImageComponent
                alt="Jasa Pest Control"
                key={item.id}
                src={item.image}
                className={
                  "img-fluid layananPest ms-2 mt-2 border bg-white border-2"
                }
              ></ImageComponent>
            ))}
          </div>
        </div>
      </Sectionn>
      <Sectionn className={"section3"}>
        <div className="row mt-5">
          <h5 className="fs-3 text-danger text-family "> Rodent Control</h5>
          <h6 className="text-primary fw-bold">(Pengendalian Hama Tikus)</h6>
          <div className="d-flex Layanan flex-wrap">
            {LayananImageRodent.map((item, index) => (
              <ImageComponent
                alt="Jasa Pengendalian Tikus"
                key={item.id}
                src={item.image}
                className={
                  "img-fluid layananPest ms-2 mt-2 border border-primary"
                }
              ></ImageComponent>
            ))}
          </div>
        </div>
      </Sectionn>

      <Sectionn className={"section4 bg-info bg-opacity-50 pt-1"}>
        <div className="row mt-5">
          <h5 className="fs-3 text-danger text-family"> Disinfeksi</h5>
          <h6 className="text-primary fw-bold">
            (Penyemprotan disinfektan virus, bakteri, jamur)
          </h6>
          <div className="d-flex Layanan flex-wrap">
            {LayananImageDisinfeksi.map((item, index) => (
              <ImageComponent
                alt="Jasa Disinfeksi"
                key={item.id}
                src={item.image}
                className={
                  "img-fluid layananPest ms-2 mt-2 border bg-white border-2"
                }
              ></ImageComponent>
            ))}
          </div>
        </div>
      </Sectionn>
      <Sectionn className={"section4"}>
        <div className="row mt-5">
          <h5 className="fs-3 text-danger text-family "> Termite Control</h5>
          <h6 className="text-primary fw-bold">(Pengendalian Hama Rayap)</h6>
          <div className="d-flex Layanan flex-wrap">
            {LayananImageTermite.map((item, index) => (
              <ImageComponent
                alt="Jasa Pengendalian Rayap"
                key={item.id}
                src={item.image}
                className={
                  "img-fluid layananPest ms-2 mt-2 border border-primary border-2"
                }
              ></ImageComponent>
            ))}
          </div>
        </div>
      </Sectionn>
      <Sectionn className={"bg-info bg-opacity-50 pt-5"}>
        <div className="row">
          <div className="col-md-6">
            <h5 className="d-flex align-items-center">
              <span className="me-1">
                {/* Ganti ms-1 dengan me-2 untuk mengatur jarak */}
                <GeoAlt></GeoAlt>
              </span>
              Lokasi
            </h5>
            <ButtonWithLink
              className={"p-0 "}
              href={"https://maps.app.goo.gl/QHFYvRPRwDw3vTX19"}
            >
              Gg.Perintis, Keboan Anom, Gedangan, Sidoarjo, Jawa Timur.
            </ButtonWithLink>
            <p></p>
          </div>
          <div className="col-md-6 text-sm-start text-md-end">
            <h5>
              <span className="me-1">
                {/* Ganti ms-1 dengan me-2 untuk mengatur jarak */}
                <Globe></Globe>
              </span>
              Unit Pelayanan
            </h5>
            <p>Jawa Timur - Indonesia</p>
          </div>
        </div>
      </Sectionn>
      <Footer />
    </div>
  );
};

export default AboutUs;
