import React from "react";
import Headerr from "../Components/Headerr";
import Main from "./Home/Main";

export const Home = () => {
  return (
    <div>
      <Headerr/>
      <Main/>
    </div>
  );
};

export default Home;
