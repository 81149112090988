import axios from "axios";
import Cookies from "js-cookie";

const apiBaseUrl = `${process.env.REACT_APP_BACKEND_URL}teknisi`;
const apiGenerateToken = `${process.env.REACT_APP_BACKEND_URL}`;
const generateTokenTeknisi = (token, dataTeknisi) => {
  // const token = Cookies.get("auth");
  return axios.put(`${apiGenerateToken}generateTeknisi`, dataTeknisi, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
    withCredentials: true,
  });
};

const getTeknisiById = (id, token) => {
  console.log(`id teknisi: ${id}`);
  // const token = Cookies.get("_auth_");
  return axios.get(`${apiBaseUrl}/getData/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const getDataTeknisi = (token) => {
  // const token = Cookies.get("_auth_");

  return axios.get(`${apiBaseUrl}/getAllData`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const getAllTeknisi = (keyword = "", page = 0, limit = 10, token) => {
  // const token = Cookies.get("_auth_");
  return axios.get(
    `${apiBaseUrl}/getData?search_query=${keyword}&page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      },
    }
  );
};

// mengambil data laporan treatmen berdasarkan id teknisi
const getLaporanTreatment = (id, dataLaporanTreatment, token) => {
  // const token = Cookies.get("_auth_");
  console.log("Laporan Treatment");
  console.log(`id teknisi: ${id}`);
  return axios.post(
    `${apiBaseUrl}/laporantreatment/${id}`,
    dataLaporanTreatment,
    {
      headers: {
        Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      },
    }
  );
};

const addTeknisi = (teknisi, token) => {
  // const token = Cookies.get("_auth_");
  return axios.post(`${apiBaseUrl}/createData`, teknisi, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const updateData = (idTeknisi, dataTeknisi, token) => {
  // const token = Cookies.get("_auth_");
  return axios.put(`${apiBaseUrl}/updateData/${idTeknisi}`, dataTeknisi, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const deleteTeknisi = (idTeknisi, token) => {
  // const token = Cookies.get("_auth_");
  return axios.delete(`${apiBaseUrl}/deleteData/${idTeknisi}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const apiTeknisi = `${process.env.REACT_APP_BACKEND_PELANGGAN}pelanggan`;

const getAllPenjadwalan = (startYear, endYear, token) => {
  // const token = Cookie.get("_auth_");
  return axios.get(`${apiBaseUrl}/getData/${startYear}/${endYear}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

export { getAllPenjadwalan };

export {
  generateTokenTeknisi,
  getDataTeknisi,
  getAllTeknisi,
  getTeknisiById,
  getLaporanTreatment,
  addTeknisi,
  updateData,
  deleteTeknisi,
};
