import React, { useContext, useEffect, useState } from "react";
import Sectionn from "../../../Components/Sectionn";
import "../../../CSS/Admin/pelanggan.css";
import Headerr from "../../../Components/Headerr";
import ModalDelete from "../../../Components/ModalDelete";
import ReactPaginate from "react-paginate";
import AdminAddLaporan from "./AddLaporan";

import {
  deleteLaporan,
  getAllLaporan,
} from "../../../Model/Laporan/laporanModel";
import AdminEditLaporan from "./EditLaporan";
import { AuthContext } from "../../../Routes/AuthContext";

const AdminLaporan = () => {
  const { token } = useContext(AuthContext);
  const currentYear = new Date().getFullYear();

  const [showModal, setShowModal] = useState(false);
  const [dataLaporan, setDataLaporan] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  // Handle Modal edit,Add Tagihan
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  // Handle alert
  const [errorMessages, setErrorMessages] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // state untuk pagination
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [tanggalExpired, setTanggalExpired] = useState("");
  const [keywordTanggalExpired, setKeywordTanggalExpired] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  // ----------------------------------------------
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const closeAlert = () => {
    setShowErrorAlert(false);
    setShowSuccessAlert(false);
  };

  // fungsi handleConfirmDelete yang menghapus data tagihan, dan menutup modal
  const handleConfirmDelete = async () => {
    deleteLaporan(selectedId, token)
      .then((response) => {
        setShowModal(false);
        setTriggerFetch((prev) => !prev);
        setShowSuccessAlert(true);
        setTimeout(closeAlert, 3000);
      })
      .catch((error) => {
        setErrorMessages(error.response.data.errMessage);
        setShowErrorAlert(true);
        setTimeout(closeAlert, 3000);
      });
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  // Fungsi untuk mencari data
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeywordTanggalExpired(tanggalExpired);
    setKeyword(query);
  };
  const changePage = ({ selected }) => {
    setPage(selected);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };
  // ----------------------------------------------

  useEffect(() => {
    getAllLaporan(keyword, page, limit, keywordTanggalExpired, token)
      .then((response) => {
        setDataLaporan(response.data.data);
        setPage(response.data.page);
        setPages(response.data.totalPage);
        setRows(response.data.totalRows);
      })
      .catch((error) => {
        setErrorMessages(error.response.data.errMessage);
      });
  }, [keyword, page, triggerFetch, keywordTanggalExpired]);

  return (
    <div>
      <Headerr />
      <Sectionn>
        {showErrorAlert && (
          <div
            className="alert alert-danger border-danger position-fixed top-50 start-50 translate-middle"
            role="alert"
          >
            {errorMessages}
          </div>
        )}
        <h1 className="text-center mb-5 title-penjadwalan">Data Laporan</h1>

        <form onSubmit={searchData}>
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control form-control-sm  me-2"
                placeholder="Masukkan Id Laporan atau nama pelanggan"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control form-control-sm me-2 mt-2 mt-md-0"
                placeholder="Masukkan Tahun Laporan"
                value={tanggalExpired}
                onChange={(e) => setTanggalExpired(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-start mb-3 mt-2">
            <button type="submit" className="btn btn-primary btn-sm">
              Search
            </button>
          </div>
        </form>
        <div className="mb-5">
          <button
            className={"btn btn-primary btn-sm me-2 add-hover sizee"}
            // href={"/admin-home/addTagihan"}
            onClick={() => setShowModalAdd(true)}
          >
            Tambah Data
          </button>
        </div>

        {dataLaporan.length === 0 ? (
          <div className=""></div>
        ) : (
          dataLaporan.map((item, index) => (
            <div className="card mt-4 d-md-none">
              <div className="card-header fw-bold">
                ID Laporan {item.id_laporan}
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <span className="me-2">ID Pelanggan : </span>
                  {item.idpelanggan}
                </li>
                <li className="list-group-item">
                  <span className="me-2">Nama Pelanggan : </span>
                  {item.nama_pelanggan}
                </li>
                <li className="list-group-item">
                  <span className="me-2">Alamat :</span> {item.alamat}
                </li>
                <li className="list-group-item">
                  <span className="me-2">File Laporan :</span>
                  <a
                    className="fs-6"
                    href={item.file_laporan}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.nama_file_laporan}
                  </a>
                </li>

                <li className="list-group-item">
                  <span className="me-2">Tanggal Laporan :</span>
                  {item.tanggal_laporan}
                </li>
                <li className="list-group-item">
                  <span className="me-2">Catatan :</span>
                  <textarea
                    className="form-control"
                    disabled
                    // placeholder="Leave a comment here"
                    value={item.catatan}
                    id="floatingTextarea"
                  ></textarea>
                </li>
                <li className="list-group-item">
                  <span className="me-2">Created At :</span>
                  {item.created_at}
                </li>
                <li className="list-group-item">
                  <span className="me-2">Updated At :</span>
                  {item.updated_at}
                </li>

                <li className="list-group-item">
                  <span className="me-2">Action :</span>
                  <button
                    // to={`/admin-home/editTagihan/${item.idtagihan}`}
                    className="btn btn-warning btn-sm me-2  mb-sm-1 "
                    onClick={() => {
                      setShowModalEdit(true);
                      setSelectedId(item.id_laporan);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className={"btn btn-danger btn-sm "}
                    onClick={() => handleDeleteClick(item.id_laporan)}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          ))
        )}
        {dataLaporan.length === 0 ? (
          <div className="container text-center text-white p-2 bg-secondary">
            Data Laporan Kosong
          </div>
        ) : (
          <div className="d-none d-md-block">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Id Laporan</th>
                  <th scope="col">Id Pelanggan</th>
                  <th scope="col">Nama Pelanggan</th>
                  <th scope="col">Alamat</th>
                  <th scope="col">File Laporan</th>
                  <th scope="col">Tanggal Laporan</th>
                  <th scope="col">Catatan</th>
                  <th scope="col">Created at</th>
                  <th scope="col">Updated at</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLaporan.map((item, index) => (
                  <tr key={index + 1}>
                    <td className="">{index + 1}</td>
                    <td className="">{item.id_laporan}</td>
                    <td className="">{item.idpelanggan}</td>
                    <td className="">{item.nama_pelanggan}</td>
                    <td
                      className=""
                      style={{
                        minWidth: "10rem",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item.alamat}
                    </td>

                    <td className="">
                      <a
                        className="fs-6"
                        href={item.file_laporan}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.nama_file_laporan}
                      </a>
                    </td>

                    <td className="">{item.tanggal_laporan}</td>
                    <td className="">{item.catatan}</td>
                    <td className="">{item.created_at}</td>
                    <td className="">{item.updated_at}</td>

                    <td className="px-2 text-">
                      <button
                        // to={`/admin-home/editTagihan/${item.idtagihan}`}
                        className="btn btn-warning btn-sm me-2  mb-sm-1 "
                        onClick={() => {
                          setShowModalEdit(true);
                          setSelectedId(item.id_laporan);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={"btn btn-danger btn-sm "}
                        onClick={() => handleDeleteClick(item.id_laporan)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <p className="mt-4">
          Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}
        </p>
        <p className="text-centered text-danger">{msg}</p>
        <nav
          className="Page navigation example"
          key={rows}
          role="navigation"
          aria-label="pagination"
        >
          <ReactPaginate
            previousLabel={"< Prev"}
            nextLabel={"Next >"}
            pageCount={Math.min(10, pages)}
            onPageChange={changePage}
            containerClassName={"pagination pagination-sm"}
            pageLinkClassName={"page-link"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            activeLinkClassName={"active"}
            disabledClassName={"disabled"}
          />
        </nav>
      </Sectionn>
      <AdminEditLaporan
        idLaporan={selectedId}
        onHide={() => setShowModalEdit(false)}
        setTrigger={setTriggerFetch}
        show={showModalEdit}
      />
      <AdminAddLaporan
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        setTrigger={setTriggerFetch}
      />
      <ModalDelete
        showModal={showModal}
        onCLose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        commandText={"Apakah Anda yakin ingin menghapus data ini?"}
      ></ModalDelete>
    </div>
  );
};

export default AdminLaporan;
