import "../../CSS/Home/main.css";
import "../../CSS/Home/galery.css";
import Image from "../../Components/Image.js";
import {
  ArrowBarUp,
  ArrowRepeat,
  ArrowUpCircle,
  BookHalf,
  CheckCircle,
  PersonGear,
  ShieldShaded,
} from "react-bootstrap-icons";
import { useState } from "react";

import { Facebook, Instagram, Whatsapp, Youtube } from "react-bootstrap-icons";
import pest1 from "../../Assets/Image/Pest control/pest control1.png";
import galery1 from "../../Assets/Galery/Galeri1.jpg";
import galery2 from "../../Assets/Galery/Galeri2.jpg";
import galery3 from "../../Assets/Galery/Galeri3.jpg";
import galery4 from "../../Assets/Galery/Galeri4.jpg";
import galery5 from "../../Assets/Galery/Galeri5.jpg";
import galery6 from "../../Assets/Galery/Galeri6.jpg";
import galery7 from "../../Assets/Galery/Galeri7.jpg";
import galery8 from "../../Assets/Galery/Galeri8.jpg";
import galery9 from "../../Assets/Galery/Galeri9.jpg";
import galery10 from "../../Assets/Galery/Galeri10.jpg";
import jabataTangan from "../../Assets/Galery/jabatTangan.jpg";

import IkonFoto from "../../Assets/Image/Home/IkonFoto.jpg";

import ImageComponent from "../../Components/Image.js";
import Sectionn from "../../Components/Sectionn";
import Footer from "../../Components/Footer.js";

const images = [
  galery1,
  galery2,
  galery3,
  galery4,
  galery5,
  galery6,
  galery7,
  galery8,
  galery9,
  galery10,
];

const Main = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <main className="mb-0 pb-0">
      <ArrowUpCircle
        size={30}
        color="#353CFF"
        className=""
        onClick={scrollTop}
        style={{
          zIndex: 999,
          height: 40,
          display: showScroll ? "flex" : "none",
          position: "fixed", // Add this
          bottom: "1rem", // Add this
          right: "1rem", // Add this
        }}
      ></ArrowUpCircle>
      <Sectionn className={"section2"}>
        <div className="row">
          <div className="col-md-6 text-center">
            <div>
              <Image
                src={IkonFoto}
                alt={"Pest 1"}
                className={"img-fluid w-75"}
              ></Image>
            </div>
          </div>

          <div className="col-md-6  d-flex align-items-center">
            <div className="text-md-start text-center ">
              <h1 className="mb-2 fw-bold text-danger">
                TATA <span className="fs-4 colorPestControl">Pest Control</span>
              </h1>
              <ArrowBarUp
                size={30}
                color="#353CFF"
                className="scrollTop"
                onClick={scrollTop}
                style={{ height: 40, display: showScroll ? "flex" : "none" }}
              ></ArrowBarUp>
              <p className="main-text fs-5 name-deskripsi ">
                Selamat datang di Tata Sinar Gemilang, spesialis pengendalian
                hama terpercaya dan berpengalaman. Kami berdedikasi untuk
                melindungi keluarga, karyawan,properti dan bisnis Anda dari
                serangan hama yang merusak dan mengganggu.
              </p>
            </div>
          </div>
        </div>
      </Sectionn>

      <Sectionn className={"section3 bg-info bg-opacity-50"}>
        <h2 className="text-center pt-4 title ">Jasa Pelayanan Kami</h2>
        <hr class="pembatas" />
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div class="card bg-light border border-primary">
              <div class="card-body">
                <h5 class="card-title fs-4 text-danger text-family text-uppercase">
                  Pest Control
                </h5>
                <h6 class="card-subtitle mb-2 text-primary">
                  (Pengendalian Hama Kecoak, Nyamuk, Semut, Ulat, DLL)
                </h6>
                <p class="card-text">
                  Layanan Pest Control kami dirancang untuk melindungi rumah dan
                  bisnis Anda dari berbagai jenis hama. Menggunakan teknik dan
                  peralatan terkini, kami memberikan solusi cepat dan efektif
                  untuk mengendalikan dan mencegah invasi hama. Jangan biarkan
                  hama mengganggu kenyamanan dan kesehatan Anda - temukan solusi
                  tepat di Tata Sinar Gemilang.
                </p>
                <a href="/pest-control" class="card-link">
                  lihat lebih detail
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
            <div class="card bg-light border border-primary">
              <div class="card-body">
                <h5 class="card-title fs-4 text-danger text-family text-uppercase">
                  Rodent Control
                </h5>
                <h6 class="card-subtitle mb-2 text-primary">
                  (Pengendalian Hama Tikus)
                </h6>
                <p class="card-text">
                  Rodent seperti tikus dan marmut bisa menjadi hama yang sangat
                  merusak dan berbahaya. Dengan layanan Rodent Control kami,
                  kami menawarkan metode teruji dan efektif untuk mendeteksi,
                  mengendalikan, dan mencegah hama ini. Percayakan kepada Tata
                  Sinar Gemilang untuk menjaga properti Anda dari ancaman hama
                  ini.
                </p>
                <a href="/rodent-control" class="card-link">
                  lihat lebih detail
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-4 mt-lg-0">
            <div class="card bg-light border border-primary">
              <div class="card-body">
                <h5 class="card-title fs-4 text-danger text-family text-uppercase">
                  {" "}
                  Termite Control
                </h5>
                <h6 class="card-subtitle mb-2 text-primary">
                  (Pengendalian Hama Rayap)
                </h6>
                <p class="card-text">
                  Rayap dapat merusak struktur bangunan dan mengakibatkan
                  kerusakan besar. Layanan Termite Control kami memberikan
                  perlindungan yang kuat dan bertahan lama terhadap serangan
                  rayap. Kami melakukan pemeriksaan menyeluruh dan menawarkan
                  perawatan serta pencegahan yang dirancang khusus untuk
                  kebutuhan Anda. Dengan Tata Sinar Gemilang, properti Anda
                  berada dalam perlindungan yang baik."
                </p>
                <a href="/termite-control" class="card-link">
                  lihat lebih detail
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-md-4 mt-4 mt-lg-4">
            <div class="card bg-light border border-primary">
              <div class="card-body">
                <h5 class="card-title fs-4 text-danger text-family text-uppercase">
                  Disinfeksi
                </h5>
                <h6 class="card-subtitle mb-2 text-primary">
                  (Penyemprotan disinfektan virus, bakteri, jamur)
                </h6>
                <p class="card-text">
                  Di era yang penuh tantangan kesehatan ini, layanan Disinfektan
                  kami memberikan perlindungan ekstra untuk rumah dan tempat
                  kerja Anda. Kami menggunakan disinfektan berstandar industri
                  yang aman namun kuat, yang dirancang untuk menghilangkan
                  kuman, bakteri, dan virus. Biarkan kami membantu menjaga
                  lingkungan Anda bersih dan sehat.
                </p>
                <a href="/disinfeksi" class="card-link">
                  lihat lebih detail
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-md-4 mt-4 mt-lg-4">
            <div class="card bg-light border border-primary">
              <div class="card-body">
                <h1 class="card-title fs-4 text-danger text-family">
                  Fumigasi
                </h1>
                <h6 class="card-subtitle mb-2 text-primary">
                  (Sulfuryl Fluoride, Methyl Bromide, Phosphine)
                </h6>
                <p class="card-text">
                  Pemberantasan seluruh stadia hama menggunakan gas beracun yang
                  pada tempat kedap udara dengan konsentrasi tertentu, waktu
                  tertentu dan dosis tertentu
                </p>
                <a href="/fumigasi" class="card-link">
                  lihat lebih detail
                </a>
              </div>
            </div>
          </div>
        </div>
      </Sectionn>
      <Sectionn>
        <div className="row">
          <h1 className="text-center text-danger fs-1 title  border-bottom border-3">
            Kenapa harus kami "TATA pest control" !?
          </h1>
          <p className="fs-4 text-center text-primary  fw-bold">
            Lindungi keluarga, karyawan dan properti anda dari gangguan atau
            kerusakan akibat hama
          </p>
          <div className="d-flex flex-column flex-lg-row ">
            <ul className="">
              <li className="liElement fs-6  pt-4 fw-bold text-center text-uppercase d-flex flex-column align-items-center text-primary">
                <ArrowRepeat size={50} color="red" className="me-2 mb-sm-2" />{" "}
                Treatment aman dan ramah lingkungan
              </li>
              <li className="liElement fs-6  pt-4 fw-bold text-center text-uppercase d-flex flex-column align-items-center text-primary">
                <BookHalf size={50} color="red" className="me-2" /> Pelayanan
                berkualitas dapat dipertanggungjawabkan
              </li>
              <li className="liElement fs-6  pt-4 fw-bold text-center text-uppercase d-flex flex-column align-items-center text-primary">
                <ShieldShaded size={50} color="red" className="me-2" />{" "}
                Perlindungan jangka panjang bebas hama dan bergaransi
              </li>
              <li className="liElement fs-6  pt-4 fw-bold text-center text-uppercase d-flex flex-column align-items-center text-primary">
                <span className="">
                  <PersonGear size={50} color="red" className="me-2" />
                </span>
                Teknisi berkompeten, terpercaya dan berpengalaman di bidangnya
              </li>
            </ul>
            <div className="text-center pt-3 ps-lg-5 ps-0 mt-lg-5">
              <ImageComponent
                className={"img-fluid "}
                src={jabataTangan}
              ></ImageComponent>
            </div>
          </div>
        </div>
      </Sectionn>

      <Sectionn className={"section5 bg-info bg-opacity-50"}>
        <h1 className="text-center title-galery pt-4">Galery</h1>
        <hr class="pembatas" />

        <div className="mt-5 d-flex section5-galery ">
          {images.map((image, index) => (
            <div className="mt-3 " key={index}>
              <div className="image-container text-center">
                <ImageComponent
                  src={image}
                  alt={`galery${index + 1}`}
                  className="img-fluid rounded border border-primary border-1 "
                />
              </div>
            </div>
          ))}
        </div>
      </Sectionn>
      <Footer />
    </main>
  );
};

export default Main;
