import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import { ProtectedRoute } from "./Routes/ProtectedRoute";
// Import Page Utama
import KatalogHarga from "./Page/Admin/Katalog Harga/KatalogHarga";
import Login from "./Page/Login";
import Register from "./Page/Register";
import Home from "./Page/Home";
import AboutUs from "./Page/AboutUs";
import AdminRoutes from "./Routes/RouteAdmin";
import ContactUs from "./Page/ContactUs";
import PelangganRoute from "./Routes/RoutePelanggan";
import NotFound from "./Page/NotFound/NotFound";
import { AuthProvider } from "./Routes/AuthContext";
import { ProtectedPelangganRoute } from "./Routes/ProtectedPelangganRoute";
import { ProtectedRouteTeknisi } from "./Routes/ProtectedRouteTeknisi";
import TeknisiRoute from "./Routes/RouteTeknisi";

// Layanan Kami Route
const PestControl = lazy(() => import("./Page/LayananKami/PestControl"));
const RodentControl = lazy(() => import("./Page/LayananKami/RodentControl"));
const TermiteControl = lazy(() => import("./Page/LayananKami/TermiteControl"));
const Disinfeksi = lazy(() => import("./Page/LayananKami/Disinfeksi"));
const Fumigasi = lazy(() => import("./Page/LayananKami/Fumigasi"));

function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/katalog-harga" element={<KatalogHarga />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          {/* Layanan Kami Route */}
          <Route path="/pest-control" element={<PestControl />}></Route>
          <Route path="/rodent-control" element={<RodentControl />}></Route>
          <Route path="/termite-control" element={<TermiteControl />}></Route>
          <Route path="/disinfeksi" element={<Disinfeksi />}></Route>
          <Route path="/fumigasi" element={<Fumigasi />}></Route>

          <Route
            path="/admin-home"
            element={
              <ProtectedRoute requiredRole="admin">
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-home/*"
            element={
              <ProtectedRoute requiredRole="admin">
                <AdminRoutes />
              </ProtectedRoute>
            }
          />

          {/* Teknisi Route */}
          <Route
            path="/teknisi/*"
            element={
              <ProtectedRouteTeknisi requiredRole="teknisi">
                <TeknisiRoute />
              </ProtectedRouteTeknisi>
            }
          />

          {/* Pelanggan Route */}

          <Route
            path="/pelanggan"
            element={
              <ProtectedPelangganRoute requiredRole="pelanggan">
                <Home />
              </ProtectedPelangganRoute>
            }
          />
          <Route
            path="/pelanggan/*"
            element={
              <ProtectedPelangganRoute requiredRole="pelanggan">
                <PelangganRoute />
              </ProtectedPelangganRoute>
            }
          />

          {/* Page Tidak ada  */}
          <Route path="*" element={<NotFound />} />
        </Routes>{" "}
      </Suspense>
    </AuthProvider>
  );
}

export default App;
