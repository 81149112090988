import axios from "axios";
import Cookies from "js-cookie";
const apiBaseUrl = `${process.env.REACT_APP_BACKEND_URL}pelanggan`;

const getDataPelanggan = (token) => {
  // const token = Cookies.get("auth");
  return axios.get(`${apiBaseUrl}/getAllData`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
    withCredentials: true,
  });
};

const getPelangganById = (id, token) => {
  // const token = Cookies.get("auth");

  return axios.get(`${apiBaseUrl}/getData/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
    withCredentials: true,
  });
};

const getAllPelanggan = (
  keyword = "",
  page = 0,
  limit = 10,
  jenisPekerjaan,
  token
) => {
  // const token = Cookies.get("auth");

  return axios.get(
    `${apiBaseUrl}/getData?search_query=${keyword}&page=${page}&limit=${limit}&jenis_pekerjaan=${jenisPekerjaan}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      },
      withCredentials: true,
    }
  );
};

const apiGenerateToken = `${process.env.REACT_APP_BACKEND_URL}`;
// http://localhost:4000/api/generateToken

const generateToken = (token, dataPelanggan) => {
  // const token = Cookies.get("auth");
  return axios.put(`${apiGenerateToken}generateToken`, dataPelanggan, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const addPelanggan = (pelanggan, token) => {
  // const token = Cookies.get("auth");
  return axios.post(`${apiBaseUrl}/createData`, pelanggan, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

// editPeriodeKunjungan
const editPeriodeKunjungan = (dataPelanggan, token) => {
  // const token = Cookie.get("_auth_");
  return axios.put(`${apiBaseUrl}/updatePeriodeKunjungan`, dataPelanggan, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const editPelanggan = (idpelanggan, dataPelanggan, token) => {
  // const token = Cookies.get("auth");
  return axios.put(`${apiBaseUrl}/updateData/${idpelanggan}`, dataPelanggan, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
    withCredentials: true,
  });
};

const deletePelanggan = (idpelanggan, token) => {
  // const token = Cookies.get("auth");

  return axios.delete(`${apiBaseUrl}/deleteData/${idpelanggan}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
    withCredentials: true,
  });
};

export {
  generateToken,
  getPelangganById,
  getDataPelanggan,
  getAllPelanggan,
  addPelanggan,
  editPelanggan,
  editPeriodeKunjungan,
  deletePelanggan,
};
