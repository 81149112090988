import axios from "axios";
import Cookie from "js-cookie";

const apiBaseUrl = `${process.env.REACT_APP_BACKEND_URL}penjadwalan`;
const getDataPenjadwalan = (token) => {
  // const token = Cookie.get("_auth_");
  return axios.get(`${apiBaseUrl}/getAllData`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

// get data laporan treatment bulanan
const getLaporanTreatmentBulanan = (data, token) => {
  return axios.post(`${apiBaseUrl}/getLaporanTreatmentBulanan`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// get data laporan treatment bulanan by id pelanggan
const getLaporanTreatmentBulananByIdPelanggan = (id, bulan, tahun, token) => {
  console.log("getLaporanTreatmentBulananByIdPelanggan", id, bulan, tahun);
  return axios.get(
    `${apiBaseUrl}/getLaporanTreatmentBulanan/${id}/${bulan}/${tahun}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getPenjadwalanById = (id, token) => {
  // const token = Cookie.get("_auth_");
  return axios.get(`${apiBaseUrl}/getData/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const getPenjadwalanByIdTeknisi = (idteknisi, token) => {
  // const token = Cookie.get("_auth_");
  return axios.get(`${apiBaseUrl}/getDataByIdTeknisi/${idteknisi}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const getAllPenjadwalan = (namaPelanggan, startYear, endYear, token) => {
  return axios.get(
    `${apiBaseUrl}/getData?startYear=${startYear}&endYear=${endYear}&nama_pelanggan=${namaPelanggan}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const addPenjadwalan = (penjadwalan, token) => {
  // const token = Cookie.get("_auth_");
  return axios.post(`${apiBaseUrl}/createData`, penjadwalan, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

const editStatusPenjadwalan = (idPenjadwalan, statusPenjadwalan, token) => {
  return axios.put(
    `${apiBaseUrl}/editStatus/${idPenjadwalan}`,
    statusPenjadwalan,
    {
      headers: {
        Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      },
    }
  );
};

const editPenjadwalan = (idPenjadwalan, dataPenjadwalan, token) => {
  // const token = Cookie.get("_auth_");
  return axios.put(
    `${apiBaseUrl}/updateData/${idPenjadwalan}`,
    dataPenjadwalan,
    {
      headers: {
        Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
      },
    }
  );
};

const deletePenjadwalan = (idPenjadwalan, token) => {
  // const token = Cookie.get("_auth_");
  return axios.delete(`${apiBaseUrl}/deleteData/${idPenjadwalan}`, {
    headers: {
      Authorization: `Bearer ${token}`, // userToken adalah token JWT yang kamu dapat dari proses login/authentikasi
    },
  });
};

export {
  getLaporanTreatmentBulanan,
  getLaporanTreatmentBulananByIdPelanggan,
  getDataPenjadwalan,
  getPenjadwalanByIdTeknisi,
  getPenjadwalanById,
  getAllPenjadwalan,
  addPenjadwalan,
  editPenjadwalan,
  editStatusPenjadwalan,
  deletePenjadwalan,
};
