import axios from "axios";

// URL dasar API, sesuaikan dengan konfigurasi server Anda
const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}pemesanan`; // Contoh URL
const pemesananApi = {
  getPelangganById: (id, token) => {
    // const token = Cookies.get("_auth_");
    return axios.get(`${BASE_URL}/getData/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  // Fungsi untuk mendapatkan data pemesanan
  getData: (keyword, page, limit, token) => {
    // const token = Cookies.get("_auth_");
    return axios.get(
      `${BASE_URL}/getData?search_query=${keyword}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  // Fungsi untuk menambahkan data pemesanan
  addData: (data, token) => {
    // const token = Cookies.get("_auth_");
    return axios.post(`${BASE_URL}/addData`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  // Fungsi untuk mengedit data pemesanan
  editData: (id, data, token) => {
    // const token = Cookies.get("_auth_");
    return axios.put(`${BASE_URL}/editData/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  // Fungsi untuk menghapus data pemesanan
  deleteData: (id, token) => {
    // const token = Cookies.get("_auth_");
    return axios.delete(`${BASE_URL}/deleteData/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default pemesananApi;
