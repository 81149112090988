import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Headerr from "../../../Components/Headerr";
import Cardd from "../../../Components/Card/CardAdmin";
import Sectionn from "../../../Components/Sectionn";
import CardOtherAdmin from "../../../Components/Card/CardOtherAdmin";
import {
  deleteData,
  getData,
} from "../../../Model/KatalogHarga/katalogHargaModel";
import { Modal, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingScreen } from "../../../Components/LoadingScreen";
import { AuthContext } from "../../../Routes/AuthContext";

const KatalogHarga = () => {
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [katalogHarga, setKatalogHarga] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const location = useLocation();
  const currentLocation = location.pathname;
  let cardComponent;
  let counter = 1;
  useEffect(() => {
    getData(token)
      .then((response) => {
        setKatalogHarga(response.data.data);
        console.log("Data Katalog Harga: ");
        console.log(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.log("Terjadi kesalahan:", error);
      });
  }, [triggerFetch]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (katalogHarga && katalogHarga.length > 0) {
    if (currentLocation.startsWith("/admin-home")) {
      cardComponent = (
        <div className="row">
          <div className="d-flex justify-content-end mb-2">
            <a
              href="/admin-home/addKatalog"
              className="btn btn-primary btn-sm me-2 p-1 rounded"
            >
              Tambah Data
            </a>
          </div>

          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
          />
          {katalogHarga.map((data) => (
            <Cardd
              title={data.jenis_layanan}
              harga={data.harga}
              deskripsi={data.deskripsi}
              minimum_cost={data.minimum_cost}
              luas={data.minimal_luasan}
              idKatalog={data.idkatalog_harga}
              setTriggerFetch={setTriggerFetch}
            ></Cardd>
          ))}
        </div>
      );
    } else {
      cardComponent = (
        <div className="row">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
          />
          {katalogHarga.map((data) => (
            <CardOtherAdmin
              title={data.jenis_layanan}
              harga={data.harga}
              deskripsi={data.deskripsi}
              minimum_cost={data.minimum_cost}
              luas={data.minimal_luasan}
            ></CardOtherAdmin>
          ))}
        </div>
      );
    }
  } else {
    // Jika katalogHarga kosong atau belum terisi
    cardComponent = <p>Loading data...</p>; // atau komponen loading lainnya
  }

  return (
    <div>
      <Headerr></Headerr>
      <Sectionn>{cardComponent}</Sectionn>
    </div>
  );
};

export default KatalogHarga;
