import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ModalDelete = ({ commandText, showModal, onCLose, onConfirm }) => {
  return (
    <>
      {/* ... your other component code ... */}

      {/* Modal for deletion confirmation */}
      <Modal show={showModal} onHide={onCLose}>
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi Penghapusan</Modal.Title>
        </Modal.Header>
        <Modal.Body>{commandText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCLose}>
            Batal
          </Button>
          <Button variant="danger" onClick={onConfirm}>
            Hapus
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDelete;
