import React from "react";
import Headerr from "../Components/Headerr";


const Register = () => {
  return (
    <div>
    <Headerr></Headerr>
    <h1>Ini Halaman Register</h1>
  </div>
  );
};

export default Register;
