import { useContext } from "react";
import { AuthContext } from "../../Routes/AuthContext";
import axios from "axios";

const axiosJwt = axios.create();

export function useAxiosJwt() {
  const {
    isAuthenticated,
    role,
    setRole,
    token,
    setToken,
    setIsAuthenticated,
    expired,
    setExpired,
  } = useContext(AuthContext);

  axiosJwt.interceptors.request.use(
    async (config) => {
      const currentTime = Date.now();
      if (expired * 1000 < currentTime) {
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "refreshToken",
          {
            withCredentials: true,
          }
        );
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.token);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosJwt;
}
