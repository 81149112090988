import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Headerr from "../Components/Headerr";
import Sectionn from "../Components/Sectionn";
import UndrawImage from "../Assets/Image/ContactUs/undraw_contact_us_re_4qqt.svg";
import ImageComponent from "../Components/Image";
import pemesananApi from "../Model/Pemesanan/pemesananModel";
import "../CSS/contactUs.css";
import { Whatsapp } from "react-bootstrap-icons";
// Skema validasi menggunakan Yup
const validationSchema = Yup.object({
  nama: Yup.string().required("Nama wajib diisi"),
  email: Yup.string()
    .email("Masukkan format Email yang benar")
    .required("Email wajib diisi"),
  no_telp: Yup.number().required("No Telp wajib diisi"),
  permasalahan: Yup.string().required("Permasalahan wajib dipilih"),
  jenis_usaha: Yup.string().required("Pilihan Rumah atau Bisnis wajib diisi"),
  // deskripsi: Yup.string().required("Deskripsi wajib diisi"),
  // Tambahkan validasi lain sesuai kebutuhan
});

const ContactUs = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false); // State untuk notifikasi gagal
  const [disableButton, setDisableButton] = useState(false); // State untuk
  // Fungsi untuk menutup notifikasi
  const closeAlert = () => {
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
  };

  const formik = useFormik({
    initialValues: {
      nama: "",
      email: "",
      no_telp: "",
      permasalahan: "",
      permasalahan: "",
      jenis_usaha: "",
      deskripsi: "",
      // Inisialisasi nilai awal lainnya
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Memanggil fungsi addPelanggan dengan data form
      pemesananApi
        .addData(values)
        .then((response) => {
          // Handle sukses, misal menampilkan notifikasi atau redirect
          setShowSuccessAlert(true);
          setTimeout(closeAlert, 3000); // 3000 ms = 3 detik
          setDisableButton(true); // Mematikan loading setelah proses selesai
        })
        .catch((error) => {
          // Handle gagal, misal menampilkan notifikasi error
          setShowSuccessAlert(false);
          setShowErrorAlert(true);
        });
    },
  });
  return (
    <>
      <div
        className="col-md-3 col-6 text-md-center text-sm-end text-end mt-4 mt-md-0 "
        style={{
          zIndex: 999,
          position: "fixed",
          right: "1rem",
          bottom: "5rem",
        }}
      >
        <a
          href="https://wa.me/c/6287855170409"
          target="_blank"
          rel="noopener noreferrer"
          className="d-block"
          style={{
            textDecoration: "none",
          }}
        >
          <Whatsapp className="icon whatsapp fw-bold" size={30} color="green" />
          <span className="d-block fs-6 text-danger fw-bold">Wa kami !</span>
        </a>
      </div>
      <Headerr></Headerr>

      {showSuccessAlert && (
        <div
          className="alert alert-success position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          Sukses Mengirim Permintaan, Pihak kami akan segera mengabari melalu
          telp !!!
        </div>
      )}

      {showErrorAlert && (
        <div
          className="alert alert-danger position-fixed top-50 start-50 translate-middle"
          role="alert"
        >
          Gagal mengirim permintaan !!!, Silahkan ulangi lagi
        </div>
      )}
      <Sectionn>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="row justify-content-center">
              <div className="col-md-6 mb-sm-5">
                <h3 className="heading mb-4 display-6 fs-4 text-primary">
                  Hubungi Kami !!!
                </h3>
                <p className="fw-bold">
                  Lengkapi formulir singkat tersebut dan kami akan hubungi Anda
                  secepat kami bisa. Kami tidak pernah membagi informasi Anda
                  dengan pihak ketiga. Semua kolom wajib diisi kecuali yang
                  dinyatakan sebagai opsional.
                </p>

                <p>
                  <ImageComponent
                    className={"img-fluid"}
                    src={UndrawImage}
                  ></ImageComponent>{" "}
                </p>

                <h3 className="text-primary text-family mt-5 fs-6">
                  Survei, Konsultasi dan Estimasi biaya
                </h3>
                <h2 className="text-danger p-0 text-family mb-sm-5">*GRATIS</h2>
              </div>
              <div className="col-md-6 mt-sm-5">
                <form
                  onSubmit={formik.handleSubmit}
                  className="mb-5"
                  method="post"
                  id="contactForm"
                  name="nama"
                >
                  <div className="row mb-4 mt-lg-0 mt-5 mt-sm-0 ">
                    <div className="col-md-12 form-group">
                      <h6>Masukkan Nama</h6>
                      <input
                        type="text"
                        className="form-control"
                        name="nama"
                        onChange={formik.handleChange}
                        value={formik.values.nama}
                        placeholder="Your name"
                      />
                      {formik.errors.nama && formik.touched.nama ? (
                        <div className="text-danger">{formik.errors.nama}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-12 form-group">
                      <h6>Masukkan Email</h6>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placeholder="example: example23@gmail.com"
                      />
                      {formik.errors.email && formik.touched.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-12 form-group">
                      <h6>Masukkan No Telp</h6>

                      <input
                        type="number"
                        className="form-control"
                        name="no_telp"
                        onChange={formik.handleChange}
                        value={formik.values.no_telp}
                        placeholder="example: 813xxxxxx"
                      />
                      {formik.errors.no_telp && formik.touched.no_telp ? (
                        <div className="text-danger">
                          {formik.errors.no_telp}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-12 form-group">
                      <h6>Masukkan Permasalahan Hama</h6>
                      <select
                        class="form-select form-select-sm mb-3"
                        aria-label="Small select example"
                        name="permasalahan"
                        onChange={formik.handleChange}
                        value={formik.values.permasalahan}
                      >
                        <option selected>Pilih....</option>
                        <option value="Tikus">Tikus</option>
                        <option value="Nyamuk">Nyamuk</option>
                        <option value="Lalat">Lalat</option>
                        <option value="Kecoa">Kecoa</option>
                        <option value="Semut">Semut</option>
                        <option value="Rayap">Rayap</option>
                        <option value="Dll">Dll</option>
                      </select>
                      {formik.errors.permasalahan && (
                        <div className="text-danger">
                          {formik.errors.permasalahan}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <h6>
                      Apakah permintaan ini untuk rumah atau bisnis anda ?{" "}
                    </h6>
                    <div className="d-flex gap-5 align-items-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="jenis_usaha"
                          id="flexRadioDefault1"
                          value="Rumah"
                          onChange={formik.handleChange}
                          checked={formik.values.jenis_usaha === "Rumah"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Rumah
                        </label>
                      </div>
                      <div className="form-check ml-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="jenis_usaha"
                          id="flexRadioDefault2"
                          value="Bisnis"
                          onChange={formik.handleChange}
                          checked={formik.values.jenis_usaha === "Bisnis"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Bisnis
                        </label>
                      </div>
                    </div>
                    {formik.errors.jenis_usaha && (
                      <div className="text-danger">
                        {formik.errors.jenis_usaha}
                      </div>
                    )}
                  </div>

                  <div className="row mb-4">
                    <h6>Deskripsi</h6>
                    <div className="col-md-12 form-group">
                      <textarea
                        className="form-control"
                        name="deskripsi"
                        id="message"
                        cols="30"
                        rows="7"
                        placeholder="(example: Saya ada masalah hama di tempat )"
                        onChange={formik.handleChange}
                        value={formik.values.deskripsi}
                      ></textarea>
                      {formik.errors.deskripsi && (
                        <div className="text-danger">
                          {formik.errors.deskripsi}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <button
                        type="submit"
                        value=""
                        className="btn btn-primary rounded-0 py-2 px-4"
                        disabled={disableButton}
                      >
                        {formik.isSubmitting ? "Sending..." : "Send Message"}
                      </button>
                      <span className="submitting"></span>
                    </div>
                  </div>
                </form>

                {showSuccessAlert && (
                  <div id="form-message-success">
                    Your message was sent, thank you!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Sectionn>
    </>
  );
};

export default ContactUs;
